<template>
  <div class="expense-modal">
    <form autocomplete="off" @submit.prevent.stop="addExpense">
      <div class="columns mb-1 mt-2">
        <div class="column form-group"
             :class="!canSearch ? 'tooltip' : ''"
             :data-tooltip="!canSearch
             ? 'Convênio e profissional devem estar selecionados' : ''">
          <dx-autocomplete
            v-model="expense"
            :source="findExpense"
            label="name"
            track-by="id"
            :disabled="!canSearch"
            :debounce="800"
            @select="addAppointmentExpense"
            placeholder="Informe o código ou nome da cirurgia..."
            input-id="expense-name">
            <button
              slot="action"
              @click="openExpenseModal"
              class="btn btn-action input-group-btn btn-icon btn-neutral"
              tabindex="-1"
              :disabled="!canSearch || expenseModal.loading"
            ><fa-icon :icon="['fal', 'search']"></fa-icon></button>
            <template v-slot="{ item }">
              <a>
                <fa-icon :icon="['fal', 'file-medical-alt']" class="text-primary"/>
                <span class="ml-1">
                  {{ item.code | tuss }} - {{ item.name }}
                  <span v-if="item.professional && item.professional.specialty"> ({{
                      item.professional.specialty.name }})
                  </span>
                </span>
              </a>
            </template>
          </dx-autocomplete>
        </div>
      </div>
    </form>
    <template v-if="items.length > 0">
      <table class="table">
        <thead>
        <tr>
          <th style="width: 1rem">#</th>
          <th style="width: 5rem">Código</th>
          <th>Nome</th>
          <th style="width: 2rem"></th>
          <th style="width: 2rem"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in items" :key="item.id">
          <td>{{ i + 1 }}</td>
          <td>{{ item.code | tuss }}</td>
          <td>{{ item.name }}</td>
          <td>x{{ item.quantity }}</td>
          <td class="text-right" @click="deleteExpense(item)">
            <button class="btn btn-sm btn-action btn-icon btn-gray">
              <fa-icon :icon="['fal', 'times']"></fa-icon>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <template v-else>
      <div class="empty">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Procedimentos</p>
        <p class="empty-subtitle">
          Nenhuma procedimento adicionado. Informe o código ou nome no campo acima.
        </p>
      </div>
    </template>
    <dx-modal title="Lista de procedimentos"
              v-model="expenseModal.show" size="lg"
              v-if="expenseModal.show"
              id="modal-expenses-appointment">
      <div class="empty mt-2"
           v-if="expenseModal.data.items.length === 0 && !expenseModal.loading">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
        </div>
        <p class="empty-title h6">Procedimentos do profissional</p>
        <p class="empty-subtitle">
          Nenhum procedimento encontrado para este profissional. Verifique as configurações.
        </p>
      </div>
      <div class="loading loading-lg mt-2" v-else-if="expenseModal.loading" />
      <table class="table table-hover expense-modal-table" v-else>
        <thead>
        <tr>
          <th>Código</th>
          <th>Nome</th>
          <th class="text-right">Valor Paciente</th>
        </tr>
        </thead>
        <tbody>
        <tr class="c-hand" v-for="(item, i) in expenseModal.data.items" :key="i"
            @click="selectExpense(item)">
          <td>
            <span v-if="item.code">{{ item.code | tuss }}</span>
            <span v-else>-</span>
          </td>
          <td>{{ item.name }}</td>
          <td class="text-right">
            {{ item.values ? item.values.particular : 0 | currency }}
          </td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="expenseModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    saving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      source: 'appointments',
      editing: false,
      printing: false,
      deleting: false,
      loading: false,
      expense: null,
      expenseModal: {
        loading: false,
        show: false,
        data: {
          items: [],
          hasMore: false,
          limit: 0,
          offset: 0,
        },
      },
      form: {},
    };
  },
  created() {
    this.form = this.$store.state.surgeryAppointment.form;
  },
  computed: {
    isSelected() {
      return this.form.expenses
        .some(item => item.accountId === null && item.selected);
    },
    isWaiting() {
      return this.deleting || this.loading || this.saving;
    },
    canSearch() {
      return this.form.insurance.plan.id && this.form.professional.id;
    },
    items() {
      return this.form.expenses.filter(({ type }) => type !== 'surgery');
    },
  },
  methods: {
    ...mapMutations({
      addExpense: 'SurgeryAppointment.ADD_EXPENSE',
      deleteExpense: 'SurgeryAppointment.DELETE_EXPENSE',
    }),
    total(account) {
      return account.expenses
        .reduce((a, b) => a + Number(b.value.particular
          * b.quantity * (b.factor ? b.factor : 1)), 0);
    },
    async findExpense(search) {
      try {
        const { items } = await this.findExpenses({ search });
        if (items.length === 0) {
          return [{
            id: null,
            name: 'Procedimentos não encontrados neste convênio',
            quantity: 0,
            factor: 0,
            value: { insurance: 0, particular: 0, total: 0 },
          }];
        }
        return items;
      } catch (e) {
        this.$toast.$error(e);
      }
      return [];
    },
    addAppointmentExpense(expense) {
      if (!expense || expense.id === null) {
        return;
      }
      const professional = expense.professional ? expense.professional : null;
      const specialty = professional && professional.specialty
        ? professional.specialty : null;

      const found = this.form.expenses
        .find(item => (item.id === expense.id && !item.accountId));
      if (found) {
        found.quantity += 1;
      } else {
        this.addExpense({
          id: expense.id,
          selected: false,
          code: expense.code,
          name: expense.name,
          type: expense.type,
          specialty: specialty || null,
          quantity: 1,
          factor: expense.factor ? expense.factor : 1,
          value: {
            insurance: expense.definition.insuranceValue,
            particular: expense.definition.particularValue,
          },
          accountId: null,
        });
        if ((expense.definition.insuranceValue + expense.definition.particularValue) === 0) {
          this.$toast.show('Atenção! Procedimento ainda não precificado.', { type: 'error' });
        }
        this.$emit('validateScheduleInfo');
      }
      this.expense = null;
    },
    selectReferralType(item) {
      this.referralType = item.code;
      this.checkAppointment();
    },
    setPrimarySurgery(item) {
      const idx = this.items.indexOf(item);
      this.items.splice(idx, 1);
      this.items.unshift(item);
    },
    openExpenseModal() {
      this.loadExpenseModalItems();
      this.expenseModal.show = true;
    },
    async loadExpenseModalItems() {
      this.expenseModal.loading = true;
      this.expenseModal.items = [];

      try {
        const { hasMore, items } = await this.findExpenses();
        this.expenseModal.data.hasMore = hasMore;
        this.expenseModal.data.items = items;
      } catch (e) {
        this.$toast.$error(e);
      }

      this.expenseModal.loading = false;
    },
    async findExpenses(params = {}) {
      params = {
        type: 'exam,procedure,material,medicine,opme',
        professionalId: this.form.professional.id,
        insurancePlanId: this.form.insurance.plan.id,
        limit: 30,
        offset: 0,
        ...params,
      };

      const { data } = await this.$http.get('/expense-pricing', { params });
      data.items = data.items.map((item) => {
        item.id = item.expense.id;
        item.name = item.expense.name;
        item.type = item.expense.type;
        return item;
      });
      return data;
    },
    selectExpense(item) {
      this.addAppointmentExpense(item);
      this.expenseModal.show = false;
    },
    deleteAppointmentExpense(expense) {
      this.deleteExpense(expense);
    },
    hideAccountModal() {
      this.account.show = false;
    },
    checkAppointment() {
      this.$emit('check-appointment');
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
.expense-modal {
  .expense-modal-table {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    &.table-hover {
      tr:hover {
        background-color: lighten($border-color, 6%);
      }
    }
  }
}
</style>
