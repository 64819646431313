<template>
  <div>
    <dx-modal title="Gerenciar compromisso"
              :value="show" @input="close" size="lg"
              id="modal-appointment-commitment">
      <template v-if="canAccess">
        <div class="loading loading-lg" v-if="loading" />
        <template v-else>
          <div class="empty" v-if="data.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h6">Compromissos</p>
            <p class="empty-subtitle">
              Nenhum compromisso adicionado. Clique abaixo para criar.
            </p>
            <button class="btn btn-primary btn-icon btn-icon-left mt-2" @click="open">
              <fa-icon :icon="['fal', 'plus-circle']"/>Criar um compromisso
            </button>
          </div>
          <template v-else>
            <div class="text-right">
              <button class="btn btn-primary btn-icon btn-icon-left" @click="open">
                <fa-icon :icon="['fal', 'plus-circle']"/>Novo compromisso
              </button>
            </div>
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th style="width: 130px">Data</th>
                <th>Responsável</th>
                <th>Período</th>
                <th>Agenda(s)</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in data.items" :key="i">
                <td>{{ item.createdAt | date('datetime') }}</td>
                <td>{{ item.user.name }}</td>
                <td>
                  {{ item.startDate | date('datetime') }} - {{ item.endDate | date('datetime') }}
                </td>
                <td>
                  <span v-if="item.schedules && item.schedules.length > 0">
                    {{ item.schedules[0].name }}
                    <strong class="ml-2 chip text-info" v-if="item.schedules.length > 1">
                      +{{ item.schedules.length -1 }}
                    </strong>
                  </span>
                  <span v-else>-</span>
                </td>
                <td class="text-right">
                  <button class="btn btn-sm btn-action btn-icon btn-error"
                          :class="{loading: deleting}"
                          :disabled="deleting"
                          @click="remove(item.id)">
                    <fa-icon :icon="['fal', 'times']"></fa-icon>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="load-more" v-if="data.items.length > 0">
              <button
                v-if="data.hasMore && !loading"
                class="btn btn-primary"
                @click="loadMore"
                :disabled="loadingMore"
                :class="{ loading: loadingMore }"
              >Carregar mais...</button>
            </div>
          </template>
        </template>
      </template>
      <forbidden v-else />
      <template slot="footer">
        <button class="btn" @click="close">Sair</button>
      </template>
    </dx-modal>
    <dx-modal title="Novo compromisso" size="sm"
              v-model="modalNewCommitment.show"
              v-if="modalNewCommitment.show"
              id="modal-commitment">
      <div class="loading loading-lg" v-if="modalNewCommitment.loading" />
      <div class="columns" v-else>
        <div class="column col-6 form-group"
             :class="{'has-error': $v.modalNewCommitment.form.startDate.$error}">
          <label class="form-label">Data e hora inicial</label>
          <dx-input-date
            id="start-date"
            class="form-input text-center"
            v-model="modalNewCommitment.form.startDate"
            @blur="$v.modalNewCommitment.form.startDate.$touch()"
          />
          <template v-if="$v.modalNewCommitment.form.startDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.modalNewCommitment.form.startDate.required">Campo obrigatório!</div>
            <div class="form-input-hint"
                 v-else-if="!$v.modalNewCommitment.form.startDate.date">Data inválida!</div>
            <div class="form-input-hint"
                 v-else-if="!$v.modalNewCommitment.form.startDate.minDate">Data inválida!</div>
          </template>
        </div>
        <div class="column col-6 form-group"
             :class="{'has-error': $v.modalNewCommitment.form.endDate.$error}">
          <label class="form-label">Data e hora final</label>
          <dx-input-date
            id="end-date"
            class="form-input text-center"
            v-model="modalNewCommitment.form.endDate"
            @blur="$v.modalNewCommitment.form.endDate.$touch()"
          />
          <template v-if="$v.modalNewCommitment.form.endDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.modalNewCommitment.form.endDate.required">Campo obrigatório!</div>
            <div class="form-input-hint"
                 v-else-if="!$v.modalNewCommitment.form.endDate.date">Data inválida!</div>
            <div class="form-input-hint"
                 v-else-if="!$v.modalNewCommitment.form.endDate.minDate">Período inválido!</div>
          </template>
        </div>
        <div class="column col-12">
          <label class="form-switch">
            <input type="checkbox" v-model="allDay" @change="setHour">
            <i class="form-icon"></i>Dia inteiro
          </label>
        </div>
        <div class="column col-6 form-group" v-if="!allDay">
          <label class="form-label">Horário inicial</label>
          <select class="form-select text-center"
                  v-model="modalNewCommitment.form.startHour">
            <option :value="hour"
                    v-for="hour in modalNewCommitment.hours"
                    :key="hour">
              {{ hour }}
            </option>
          </select>
        </div>
        <div class="column col-6 form-group" v-if="!allDay">
          <label class="form-label">Horário final</label>
          <select class="form-select text-center"
                  v-model="modalNewCommitment.form.endHour">
            <option :value="hour"
                    v-for="hour in modalNewCommitment.hours"
                    :key="hour">
              {{ hour }}
            </option>
          </select>
        </div>
        <div class="column col-12 form-group"
             :class="{'has-error': $v.modalNewCommitment.form.scheduleId.$error}">
          <label class="form-label">Agenda</label>
          <select id="schedule" @blur="$v.modalNewCommitment.form.scheduleId.$touch()"
                  class="form-select" v-model="modalNewCommitment.form.scheduleId">
            <option value="">[Selecione a agenda]</option>
            <option value="all">Todas as agendas</option>
            <option v-for="(item, i) in modalNewCommitment.schedules"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
          <template v-if="$v.modalNewCommitment.form.scheduleId.$error">
            <div class="form-input-hint"
                 v-if="!$v.modalNewCommitment.form.scheduleId.required">Campo obrigatório!</div>
          </template>
        </div>
        <div class="column col-12 form-group">
          <label class="form-label">Notas</label>
          <textarea id="account-notes" class="form-input" rows="2"
                    v-model="modalNewCommitment.form.notes" />
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-primary btn-icon btn-icon-left mr-1"
                :class="{loading: modalNewCommitment.saving}"
                :disabled="modalNewCommitment.saving"
                v-if="!modalNewCommitment.loading"
                @click="save">
          <fa-icon :icon="['fal', 'save']"/>Salvar
        </button>
        <button class="btn" @click="modalNewCommitment.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { mapActions } from 'vuex';
import { LIST_COMMITMENTS } from '@/data/actions/modules/clinical';
import Forbidden from '@/components/auth/Forbidden.vue';
import { date, minDate } from '../../../../data/validators';
import formMixin from '../../../../mixins/form';

export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  mixins: [formMixin],
  components: {
    Forbidden,
  },
  data() {
    return {
      loading: false,
      loadingMore: false,
      deleting: false,
      allDay: true,
      data: {
        items: [],
        hasMore: false,
        limit: 20,
        offset: 0,
      },
      modalNewCommitment: {
        show: false,
        loading: false,
        saving: false,
        schedules: [],
        hours: this.loadHours(),
        form: this.formBlank(),
      },
    };
  },
  computed: {
    canAccess() {
      if (LIST_COMMITMENTS) {
        return this.$can(LIST_COMMITMENTS);
      }
      return true;
    },
  },
  validations() {
    const rules = {
      modalNewCommitment: {
        form: {
          startDate: {},
          endDate: {},
          scheduleId: { required },
        },
      },
    };

    if (!this.allDay) {
      rules.modalNewCommitment.form = {
        startDate: { required, date, minDate: minDate() },
        endDate: { required, date },
        scheduleId: { required },
      };
      if (this.modalNewCommitment.form.startDate
        && moment(this.modalNewCommitment.form.startDate).isValid()) {
        rules.modalNewCommitment.form.endDate = {
          required,
          date,
          minDate: minDate(this.modalNewCommitment.form.startDate),
        };
      }
    }

    return rules;
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions({
      loadCalendar: 'loadCalendar',
    }),
    load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      return this.$http
        .get('/appointment-commitments', { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    loadSchedules() {
      const params = {
        limit: 0,
        active: true,
      };

      return this.$http
        .get('/schedules', { params })
        .then(({ data }) => {
          this.modalNewCommitment.schedules = data.items;
        })
        .catch(() => {});
    },
    close() {
      this.$emit('close');
    },
    save() {
      this.$v.modalNewCommitment.form.$touch();
      if (this.$v.modalNewCommitment.form.$error || this.modalNewCommitment.saving) {
        return null;
      }

      const data = this.clone(this.modalNewCommitment.form);

      const diff = moment(data.endDate).diff(moment(data.startDate), 'months');

      if (diff > 4) {
        this.$toast.show('Período máximo (4 meses)', { type: 'error' });
        return null;
      }

      this.modalNewCommitment.saving = true;

      if (this.allDay) {
        delete data.startHour;
        delete data.endHour;
      }

      if (!data.scheduleId || data.scheduleId === 'all') {
        delete data.scheduleId;
      }

      return this.$http.post('/appointment-commitments', data)
        .then(({ data: result }) => {
          this.data.items.unshift(result);
          this.$toast.show('Compromisso salvo com sucesso!');
          this.modalNewCommitment.show = false;
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.modalNewCommitment.saving = false;
        });
    },
    open() {
      this.modalNewCommitment.loading = true;
      this.modalNewCommitment.form = this.formBlank();
      this.$v.modalNewCommitment.form.$reset();
      this.modalNewCommitment.show = true;

      if (this.modalNewCommitment.schedules.length === 0) {
        this.loadSchedules();
      }

      this.modalNewCommitment.loading = false;
    },
    remove(id) {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este compromisso?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              close();
              return this.$http.delete(`/appointment-commitments/${id}`)
                .then(() => {
                  const found = this.data.items.find(item => item.id === id);
                  if (found) {
                    const idx = this.data.items.indexOf(found);
                    this.data.items.splice(idx, 1);
                  }
                  this.$toast.show('Compromisso excluído com sucesso!');
                })
                .catch(() => {
                  this.$toast.error('Ocorreu um erro. Tente novamente!');
                })
                .finally(() => {
                  this.deleting = false;
                });
            },
          },
        ],
      });
    },
    loadHours() {
      const hours = [];
      const hour = '06:00';
      for (let i = 0; i < 18; i += 1) {
        hours.push(moment(hour, 'HH:mm').add(i, 'hour').format('HH:mm'));
      }
      return hours;
    },
    setHour() {
      this.modalNewCommitment.form.startHour = '06:00';
      this.modalNewCommitment.form.endHour = '23:00';
    },
    formBlank() {
      return {
        startDate: moment().format('YYYY-MM-DD'),
        startHour: '06:00',
        endDate: moment().format('YYYY-MM-DD'),
        endHour: '23:00',
        scheduleId: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import '../../../../assets/scss/variables';
@import '~assets/scss/mixins';
#modal-appointment-commitment {
  .load-more {
    margin: $layout-spacing-lg 0;
    text-align: center;
    .btn {
      padding: 0 $layout-spacing-xl;
    }
  }
}
</style>
