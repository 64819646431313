<template>
  <div class="history-modal">
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div v-else>
      <div class="columns">
        <div class="column col-3">
          <label class="form-label">Marcador</label>
          <select class="form-select"
                  v-model="form.markerId">
            <option :value="null">[Selecione]</option>
            <option v-for="(item, i) in markers"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
        </div>
        <div class="column col-5">
          <label class="form-label">Sala</label>
          <select class="form-select"
                  v-model="form.placeId">
            <option :value="null">[Selecione]</option>
            <option v-for="(item, i) in places"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
        </div>
      </div>
      <div class="divider" />
      <div class="stenci-title mt-2">Solicitante</div>
      <div class="columns">
        <div class="column col-6 col-md-12 col-sm-12 form-group">
          <label class="form-label">Nome</label>
          <dx-autocomplete
            id="indicated-name"
            v-model="indicatedModel"
            :source="findIndicated"
            label="name"
            track-by="id"
            :readonly="!!indicated.id"
            @select="setIndicated"
            @blur="onIndicatedBlur"
            :debounce="800"
            placeholder="Nome, CPF ou CRM">
            <template slot="action">
              <button
                @click="unsetIndicated"
                class="btn btn-action input-group-btn btn-icon"
                :class="indicatedModel ? 'btn-gray' : 'btn-neutral'"
                tabindex="-1">
                <fa-icon :icon="['fal', indicatedModel ? 'times' : 'search']"></fa-icon>
              </button>
            </template>
            <template v-slot="{ item }">
              <div>{{ item.name }}</div>
              <small class="text-primary" v-if="item.id === null">
                (indicado sem cadastro)
              </small>
            </template>
          </dx-autocomplete>
        </div>
        <div class="column col6 col-md-12 col-sm-12 form-group"
             :class="{'has-error': $v.indicated.type.$error}">
          <label class="form-label">Tipo</label>
          <select id="indicated-type" name="indicated-type"
                  v-model="indicated.type"
                  class="form-select" @change="$v.indicated.type.$touch()">
            <option value="">[Selecione]</option>
            <option value="collaborator">Colaborador</option>
            <option value="patient">Paciente</option>
            <option value="partner">Clínica/Laboratório</option>
            <option value="professional">Profissional da saúde</option>
          </select>
          <template v-if="$v.indicated.type.$error">
            <div class="form-input-hint"
                 v-if="!$v.indicated.type.required">Campo obrigatório</div>
          </template>
        </div>
      </div>
      <div class="columns" v-if="indicated.type === 'professional'">
        <div class="column col-2 col-md-4 col-sm-12 form-group">
          <label class="form-label">Conselho</label>
          <select id="indicated-council" class="form-select"
                  v-model="indicated.council.name"><option value="">[Selecione]</option>
            <option v-for="(text, value) in council.councils"
                    :value="value" :key="value">{{ value }}</option>
          </select>
        </div>
        <div class="column col-2 col-md-4 col-sm-12 form-group">
          <label class="form-label">UF</label>
          <select id="indicated-state" class="form-select"
                  v-model="indicated.council.state">
            <option value="">[Selecione]</option>
            <option v-for="(text, value) in states"
                    :value="value" :key="value">{{ value }}</option>
          </select>
        </div>
        <div class="column col-2 col-md-4 col-sm-12 form-group">
          <label for="indicated-record" class="form-label">Número</label>
          <input type="text" id="indicated-record" class="form-input"
                 placeholder="00000" v-model="indicated.council.record">
        </div>
        <div class="column col-6 col-md-12 col-sm-12 form-group">
          <label class="form-label">Especialidade</label>
          <dx-autocomplete
            v-model="indicatedSpecialty"
            :source="findCbo"
            label="name"
            track-by="id"
            :readonly="!!indicated.specialty.code"
            :debounce="800"
            @select="addCbo"
            placeholder="Informe o código ou nome..."
            input-id="expense-name">
            <button slot="action" @click="unsetCbo"
                    class="btn btn-action input-group-btn btn-icon btn-gray"
                    tabindex="-1">
              <fa-icon :icon="['fal', 'times']">
              </fa-icon>
            </button>
            <template v-slot="{ item }">
              <a>
                <fa-icon :icon="['fal', 'file-medical-alt']" class="text-primary"/>
                <span class="ml-1">
                  {{ item.code }} - {{ item.name }}
                </span>
              </a>
            </template>
          </dx-autocomplete>
        </div>
      </div>
      <div class="divider" />
      <div class="stenci-title">Agendamentos</div>
      <template v-if="data.items && data.items.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Histórico de agendamentos</p>
          <p class="empty-subtitle">
            Não foram encontrados outros agendamentos para este paciente.
          </p>
        </div>
      </template>
      <template v-else>
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Atendimento</th>
            <th>Profissional</th>
            <th>Convênio</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr class="c-hand" v-for="(item, i) in data.items" :key="i"
              @click="openDetail(item, i)">
            <td style="width:200px">
              <div>
                {{ item.date | date('DD/MM/YYYY') }}
                <small>({{ dateFromNow(item.date) }})</small>
              </div>
              <div class="d-flex mt-1 h-centered">
                <fa-icon
                  :icon="['fas', 'stop']"
                  :style="{ color: status.getColor(item.status) }"
                ></fa-icon>
                <small class="ml-1">{{ status.getName(item.status) }}</small>
              </div>
            </td>
            <td>
              <div>{{ item.professional.name | capitalizeName }}</div>
              <div><small>{{ item.professional.specialty }}</small></div>
            </td>
            <td>
              <div>{{ item.insurance.name }}</div>
              <div v-if="item.expenses && item.expenses.length > 0">
                <small class="text-info text-bold" v-if="item.expenses[0].return">
                  [Retorno]
                </small>
                <small>{{ item.expenses[0].name }}</small>
              </div>
              <small v-else>[Procedimento não informado]</small>
            </td>
            <td class="text-right">
              <div class="loading" v-if="detail.loading && i === idx"/>
              <fa-icon :icon="['fal', 'chevron-right']" v-else/>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="load-more" v-if="data.items.length > 0">
          <button
            v-if="data.hasMore && !loading"
            class="btn btn-primary"
            @click="loadMore"
            :disabled="loadingMore"
            :class="{ loading: loadingMore }"
          >Carregar mais...</button>
        </div>
      </template>
    </div>
    <dx-modal title="Detalhes"
              v-if="detail.show"
              v-model="detail.show"
              id="modal-history-detail">
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Data</th>
          <th>Usuário</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(event, i) in detail.data.events" :key="i">
          <td>{{ event.date | date('DD/MM/YYYY HH:mm') }}</td>
          <td>{{ getUserName(event.collaboratorId) }}</td>
          <td>{{ status.getName(event.status) }}</td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="detail.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import * as status from 'src/data/appointment-statuses';
import * as council from 'src/data/council-types';
import states from 'src/data/states';
import {
  required,
} from 'vuelidate/lib/validators';
import { capitalizeWord as capitalize } from '@/filters/capitalize';

export default {
  props: {
    patientId: {
      type: String,
    },
  },
  data() {
    return {
      status,
      council,
      states,
      markers: [],
      places: [],
      data: {
        items: [],
        hasMore: false,
        limit: 15,
        offset: 0,
      },
      loading: false,
      loadingMore: false,
      idx: '',
      indicatedModel: null,
      indicatedSpecialty: null,
      indicated: this.blankIndicatedForm(),
      detail: {
        loading: false,
        data: Object,
        collaborators: [],
        show: false,
      },
    };
  },
  created() {
    this.indicated = this.$store.state.appointment.form.indicated;
    if (!this.indicated.specialty && this.indicated.specialty.code) {
      this.indicated.specialty = this.blankIndicatedForm().specialty;
    } else {
      this.indicatedSpecialty = this.indicated.specialty;
    }

    if (this.indicated && this.indicated.name) {
      this.indicatedModel = {
        id: this.indicated.id || '',
        name: this.indicated.name,
      };
    }
  },
  mounted() {
    this.load();
    this.loadMarkers();
    this.loadPlaces();
  },
  validations() {
    return {
      indicated: {
        name: { required },
        type: { required },
      },
    };
  },
  computed: {
    ...mapState({
      professionals: state => state.appointment.professionals,
      form: state => state.appointment.form,
    }),
  },
  methods: {
    load() {
      if (!this.patientId) {
        return null;
      }

      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      return this.$http
        .get(`/patients/${this.patientId}/appointments`, { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    async openDetail(item, i) {
      this.idx = i;
      this.detail.loading = true;
      if (this.detail.collaborators.length === 0) {
        await this.loadCollaborators();
      }
      this.detail.data = item;
      this.detail.show = true;
      this.detail.loading = false;
    },
    loadMarkers() {
      const params = {
        active: true,
      };
      return this.$http.get('/markers', { params })
        .then(({ data }) => {
          this.markers = data.items;
        })
        .catch(() => {});
    },
    loadPlaces() {
      const params = {
        active: true,
        type: 'clinical',
      };
      return this.$http.get('/places', { params })
        .then(({ data }) => {
          this.places = data.items;
        })
        .catch(() => {});
    },
    findIndicated(search) {
      return this.$http.get(`/appointment-indications?search=${search}`)
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    setIndicated(data) {
      this.indicatedModel = data;
      this.indicated.name = data.name;
      if (data.type) {
        this.indicated.type = data.type;
      }
      if (data.council && data.council.name) {
        this.indicated.council.name = data.council.name;
      }
      if (data.council && data.council.state) {
        this.indicated.council.state = data.council.state;
      }
      if (data.council && data.council.record) {
        this.indicated.council.record = data.council.record;
      }
      if (data.specialty && data.specialty.code) {
        this.indicatedSpecialty = data.specialty;
        this.indicated.specialty = data.specialty;
      }
    },
    onIndicatedBlur(text) {
      this.indicated.name = text;
      this.$v.indicated.name.$touch();
    },
    unsetIndicated() {
      this.indicated = this.blankIndicatedForm();
      this.$store.state.appointment.form.indicated = this.blankIndicatedForm();
      this.indicatedModel = null;
    },
    loadCollaborators() {
      this.detail.loading = true;

      const params = {
        limit: 0,
      };

      return this.$http.get('/collaborators', { params })
        .then(({ data }) => {
          this.detail.collaborators = data.items;
        })
        .catch(() => {})
        .then(() => {
          this.detail.loading = false;
        });
    },
    getUserName(id) {
      const found = [...this.detail.collaborators, ...this.professionals]
        .find(item => item.id === id);

      if (found) {
        return found.name;
      }

      return '-';
    },
    dateFromNow(date) {
      return capitalize(moment(date).fromNow());
    },
    addCbo(cbo) {
      if (!cbo) {
        return;
      }
      this.indicated.specialty = {
        code: cbo.code,
        name: cbo.name,
      };
    },
    findCbo(search) {
      const params = {
        search,
        isSpecialty: true,
      };

      return this.$http.get('/services/cbos', { params })
        .then(({ data }) => data.items);
    },
    unsetCbo() {
      this.indicatedSpecialty = null;
      this.indicated.specialty = this.blankIndicatedForm().specialty;
    },
    blankIndicatedForm() {
      return {
        id: '',
        name: '',
        type: 'professional',
        council: {
          name: 'CRM',
          state: 'PR',
          record: '',
        },
        specialty: {
          code: '',
          name: '',
        },
      };
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  .history-modal {
    .load-more {
      margin: $layout-spacing-lg 0;
      text-align: center;
      .btn {
        padding: 0 $layout-spacing-xl;
      }
    }
  }
</style>
