<template>
  <dx-modal title="Imprimir agenda"
            :value="show" @input="close" id="modal-print-appointment">
    <template v-if="canAccess">
      <div class="columns form-group">
        <div class="column col-12 form-group">
          <label class="form-switch">
            <input type="checkbox" v-model="filter.allDay">
            <i class="form-icon"></i>Dia inteiro
          </label>
        </div>
        <div class="column col-12 form-group" v-if="isAw">
          <label class="form-label">Tipo de impressão</label>
          <select id="type" class="form-select" v-model="filter.printType">
            <option value="appointment">Agendamentos</option>
            <option value="medicine">Medicamentos</option>
            <option value="traceability">Rastreabilidade</option>
          </select>
        </div>
        <div class="column col-6 col-sm-12 form-group"
             :class="{'has-error': $v.filter.startDate.$error || $v.filter.startHour.$error}">
          <label class="form-label">Data inicial</label>
          <div class="input-group">
            <dx-input-date
              id="start-date"
              class="form-input text-center"
              v-model="filter.startDate"
              @blur="$v.filter.startDate.$touch()"
            />
            <input type="text" class="form-input text-center"
                   v-model="filter.startHour" v-if="!filter.allDay"
                   @blur="$v.filter.startHour.$touch()"
                   placeholder="00:00" v-mask="'00:00'"
            />
          </div>
        </div>
        <div class="column col-6 col-sm-12 form-group"
             :class="{'has-error': $v.filter.endDate.$error || $v.filter.endHour.$error}">
          <label class="form-label">Data final</label>
          <div class="input-group">
            <dx-input-date
              id="end-date"
              class="form-input text-center"
              v-model="filter.endDate"
              @blur="$v.filter.endDate.$touch()"
            />
            <input type="text" class="form-input text-center"
                   v-model="filter.endHour" v-if="!filter.allDay"
                   @blur="$v.filter.endHour.$touch()"
                   placeholder="00:00" v-mask="'00:00'"
            />
          </div>
        </div>
        <div class="column col-6 form-group">
          <label class="form-label">Filtrar por</label>
          <select id="type" class="form-select" v-model="filter.dateType">
            <option value="startDate">Data do agendamento</option>
            <option value="createdAt">Data da inclusão</option>
          </select>
        </div>
        <div class="column col-6 form-group">
          <label class="form-label">Marcador</label>
          <select class="form-select"
                  v-model="filter.markerId">
            <option value="">[Todos os marcadores]</option>
            <option v-for="(item, i) in markers"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
        </div>
        <div class="column col-6 form-group">
          <label class="form-label">Tipo do procedimento</label>
          <select class="form-select"
                  v-model="filter.type">
            <option value="">[Todos os tipo]</option>
            <option v-for="(text, value) in types"
                    :value="value" :key="value">{{ text }}</option>
          </select>
        </div>
        <div class="column col-6 form-group">
          <label class="form-label">Convênio</label>
          <select id="insurance" class="form-select"
                  v-model="filter.insurancePlanId">
            <option value="">Todos os convênios</option>
            <option v-for="(item, i) in insurances"
                    :value="item.plan.id" :key="i">{{ item.customName }}</option>
          </select>
        </div>
        <div class="column col-12 form-group">
          <label class="form-label">Agenda</label>
          <select id="schedule" class="form-select"
                  v-model="filter.scheduleId">
            <option value="">Todas as agendas</option>
            <option v-for="(item, i) in schedules"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
        </div>
        <div class="column col-12 form-group">
          <label class="form-label">Profissional</label>
          <select id="professional" class="form-select"
                  v-model="filter.professionalId">
            <option value="">Todos os profissionais</option>
            <option v-for="(item, i) in professionals"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
        </div>
      </div>
      <label class="form-label">Status</label>
      <div class="scroll-list-wrapper">
        <div class="scroll-list">
          <div class="list-header">
            <label class="form-checkbox">
              <input type="checkbox"
                     @change="selectAllStatuses"
                     :checked="getStatusSelected"
                     :indeterminate.prop="getStatusSelected === null">
              <i class="form-icon"></i>
            </label>
            <div class="list-title">Todos</div>
          </div>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(item, i) in statuses" :key="i">
              <td width="30px">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ item.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-gray mr-1 btn-icon btn-icon-left"
                :class="{loading: generating}"
                :disabled="printing || generating"
                @click="print('sheet')">
          <fa-icon :icon="['fal', 'file-excel']" />Gerar planilha
        </button>
        <button class="btn btn-primary mr-1 btn-icon btn-icon-left"
                :class="{loading: printing}"
                :disabled="printing || generating"
                @click="print('pdf')">
          <fa-icon :icon="['fal', 'print']" />Imprimir
        </button>
        <button class="btn" @click="close">Sair</button>
      </template>
    </template>
    <template v-else>
      <forbidden></forbidden>
      <template slot="footer">
        <button class="btn" @click="close">Sair</button>
      </template>
    </template>
  </dx-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { types } from 'src/data/appointment-types';
import { statuses as appointmentStatuses } from '@/data/appointment-statuses';
import { PRINT_APPOINTMENT_LIST } from '@/data/actions/modules/clinical';
import Forbidden from '@/components/auth/Forbidden.vue';
import { mapActions, mapState } from 'vuex';
import {
  date,
  minDate,
  hour,
} from '../../../../data/validators';

export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  components: {
    Forbidden,
  },
  data() {
    return {
      statuses: [],
      loading: false,
      printing: false,
      generating: false,
      insurances: [],
      schedules: [],
      professionals: [],
      markers: [],
      types,
      filter: {
        printType: 'appointment',
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        startHour: '06:00',
        endHour: '20:00',
        dateType: 'startDate',
        allDay: true,
        scheduleId: '',
        professionalId: '',
        insurancePlanId: '',
        type: '',
        markerId: '',
        status: '',
      },
    };
  },
  validations() {
    const rules = {
      filter: {
        startDate: { required, date },
        endDate: { required, date },
        startHour: { hour },
        endHour: { hour },
      },
    };

    if (this.filter.startDate && moment(this.filter.startDate).isValid()) {
      rules.filter.endDate = {
        required,
        date,
        minDate: minDate(this.filter.startDate),
      };
    }

    if (!this.filter.allDay) {
      rules.filter.startHour = { required, hour };
      rules.filter.endHour = { required, hour };
    }

    return rules;
  },
  mounted() {
    this.loadInsurances();
    this.loadSchedules();
    this.loadProfessionals();
    this.loadStatuses();
    this.loadMarkers();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    getStatusSelected() {
      if (!this.statuses || this.statuses.length === 0) {
        return false;
      }

      const selected = this.statuses.filter(item => item.selected);
      if (selected.length === 0) {
        return false;
      }
      if (selected.length === this.statuses.length) {
        return true;
      }
      return null;
    },
    canAccess() {
      if (PRINT_APPOINTMENT_LIST) {
        return this.$can(PRINT_APPOINTMENT_LIST);
      }
      return true;
    },
    isAw() {
      return this.user.branch.id === '5ef4ade28b64ba627e469ab3';
    },
  },
  methods: {
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    loadMarkers() {
      const params = {
        active: true,
      };
      return this.$http.get('/markers', { params })
        .then(({ data }) => {
          this.markers = data.items;
        })
        .catch(() => {});
    },
    loadStatuses() {
      this.statuses = appointmentStatuses
        .filter(({ key }) => !['blocked', 'available'].includes(key))
        .map(item => ({
          key: item.key,
          name: item.name,
          selected: true,
        }));
    },
    selectAllStatuses(e) {
      this.statuses.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    close() {
      this.$emit('close');
    },
    loadSchedules() {
      const params = {
        limit: 0,
        offset: 0,
        active: true,
      };

      return this.$http
        .get('/schedules', { params })
        .then(({ data }) => {
          this.schedules = data.items;
        })
        .catch(() => {});
    },
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          this.professionals = items;
        })
        .catch(() => {});
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insurances = data.items;
        })
        .catch(() => {});
    },
    print(reportType) {
      this.$v.filter.$touch();
      if (this.$v.filter.$error) {
        return null;
      }

      const isSheet = reportType === 'sheet';

      const startDate = this.filter.allDay
        ? this.filter.startDate
        : `${this.filter.startDate} ${this.filter.startHour}`;

      const endDate = this.filter.allDay
        ? this.filter.endDate
        : `${this.filter.endDate} ${this.filter.endHour}`;

      const minDuration = moment.duration(moment(endDate)
        .diff(moment(startDate))).asMinutes();

      if (minDuration < 0) {
        this.$toast.show('Verifique o intervalo das datas', { type: 'error' });
        return false;
      }

      const maxDuration = moment.duration(moment(endDate)
        .diff(moment(startDate))).asMonths();

      if (maxDuration >= 12) {
        this.$toast.show('Período máximo (12 meses)', { type: 'error' });
        return false;
      }

      if (isSheet) {
        this.generating = true;
      } else {
        this.printing = true;
      }

      const params = {
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
        reportType,
      };

      if (this.filter.printType) {
        params.printType = this.filter.printType;
      }

      if (this.filter.type) {
        params.type = this.filter.type;
      }

      if (this.filter.markerId) {
        params.markerId = this.filter.markerId;
      }

      if (this.filter.dateType) {
        params.dateType = this.filter.dateType;
      }

      if (this.filter.scheduleId) {
        params.scheduleId = this.filter.scheduleId;
      }

      if (this.filter.insurancePlanId) {
        params.insurancePlanId = this.filter.insurancePlanId;
      }

      if (this.filter.professionalId) {
        params.professionalId = this.filter.professionalId;
      }

      if (!this.filter.allDay) {
        params.startHour = this.filter.startHour;
        params.endHour = this.filter.endHour;
      }

      const statusItems = this.statuses
        .filter(({ selected }) => selected)
        .map(({ key }) => key);

      if (statusItems) {
        params.statuses = statusItems.join(',');
      }

      if (isSheet) {
        return this.$file
          .download('/appointments/print', params)
          .catch(() => {})
          .then(() => {
            this.generating = false;
          });
      }

      return this.$file
        .print('/appointments/print', params)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../assets/scss/variables';
  @import '~assets/scss/mixins';

  #modal-print-appointment {
    .list-header {
      align-items: center;
      border-bottom: $border-width solid $border-color;
      display: flex;
      padding-bottom: $layout-spacing;
      .list-title {
        flex: 1;
        font-weight: bold;
      }
    }
    .scroll-list-wrapper {
      border: $border-color solid $border-width;
      border-radius: $border-radius;
      flex-grow: 1;
      height: 150px;
      //margin-bottom: $layout-spacing-lg * 2;
      position: relative;
    }
    .scroll-list {
      bottom: 0;
      overflow-y: auto;
      padding: $layout-spacing $layout-spacing-lg;
      position: absolute;
      top: 0;
      width: 100%;
      @include scroll-bar();
      .table th, .table td {
        padding: 0;
      }
      .table td {
        border-bottom: none;
      }
    }
  }
</style>
