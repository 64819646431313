<template>
  <div class="appointment-modal">
    <form autocomplete="off" @submit.stop>
      <div class="columns">
        <!-- Hour -->
        <div class="column col-2 form-group" :class="{'has-error': $v.hour.$error}">
          <label for="appointment-hour" class="form-label">Horário</label>
          <div class="input-group">
            <dx-dropdown :items="statuses" label="name" class="input-group-addon"
                         @select="setStatus" :max-height="220">
              <span class="square c-hand" title="Status"
                    :style="{backgroundColor: statusColor}"></span>
              <template v-slot:item="{ item }">
                <div class="tile tile-centered c-hand">
                  <div class="tile-icon">
                    <span class="square" :style="{backgroundColor: item.color}"></span>
                  </div>
                  <div class="tile-content">{{ item.name }}</div>
                </div>
              </template>
            </dx-dropdown>
            <input type="text" id="appointment-hour" class="form-input text-center"
                   v-model="hour" @change="$v.hour.$touch()"
                   v-mask="'00:00'" placeholder="00:00" :readonly="isReadonly">
          </div>
          <template v-if="$v.hour.$error">
            <div class="form-input-hint"
                 v-if="!$v.hour.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.hour.hour">Hora inválida</div>
          </template>
        </div>

        <!-- Schedule -->
        <div class="column col-4 form-group"
             :class="{'has-error': $v.form.scheduleId.$error}">
          <label for="appointment-schedule" class="form-label">Sala</label>
          <select id="appointment-schedule" class="form-select"
                  :class="{ readonly: isReadonly }"
                  v-model="form.scheduleId" :disabled="isReadonly">
            <option value="">[Selecione a agenda]</option>
            <template v-if="schedules.length > 0">
              <option v-for="item in schedules"
                      :value="item.id" :key="item.id">{{ item.name }}</option>
            </template>
            <template v-else>
              <option :value="null">{{ noSchedulesLabel }}</option>
            </template>
          </select>
          <template v-if="$v.form.scheduleId.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.scheduleId.required">Campo obrigatório</div>
          </template>
        </div>

        <!-- Professional -->
        <div class="column col-6 form-group"
             :class="{'has-error': $v.form.professional.id.$error}">
          <label for="appointment-professional" class="form-label">Cirurgião</label>
          <select id="appointment-professional" class="form-select"
                  @blur="$v.form.professional.id.$touch()"
                  v-model="professionalSpecialtyKey">
            <option value="">[Selecione o profissional]</option>
            <option
              v-for="item in availableProfessionalsSpecialty"
              :value="item.key"
              :key="item.key">
              {{ item.name }}
<!--              <template v-if="item.specialty">({{ item.specialty.name }})</template>-->
            </option>
          </select>
          <template v-if="$v.form.professional.id.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.professional.id.required">Campo obrigatório</div>
          </template>
        </div>

        <!-- Patient -->
        <div class="column col-6 form-group" :class="{
          'has-error': $v.form.patient.id.$error,
          'patient-not-registered': isPatientNotRegistered
        }">
          <label :for="'appointment-patient'" class="form-label">Paciente</label>
          <dx-autocomplete
            v-model="patient"
            :source="findPatient"
            label="name"
            track-by="id"
            @input="setPatient"
            @focus="onPatientFocus"
            @blur="onPatientBlur"
            :debounce="800"
            placeholder="Nome do paciente, data de nascimento ou CPF"
            input-id="appointment-patient"
            :readonly="!!form.patient.id"
            ref="patientSearch"
          >
            <template slot="action">
              <button
                v-if="isPatientNotRegistered"
                @click="switchTab('patient')"
                class="btn btn-action input-group-btn btn-icon">
                <fa-icon :icon="['fal', 'pencil']"></fa-icon>
              </button>
              <button
                v-else-if="patient && patient.name"
                @click="unsetPatient"
                class="btn btn-action input-group-btn btn-icon btn-gray"
                tabindex="-1">
                <fa-icon :icon="['fal', 'times']"></fa-icon>
              </button>
              <button
                v-else
                @click="showHospitalizedPatients"
                class="btn btn-action input-group-btn btn-icon btn-neutral"
                tabindex="-1">
                <fa-icon :icon="['fal', 'search']"></fa-icon>
              </button>
            </template>
            <template v-slot="{ item }">
              <a v-if="item.id">{{ item.name }} ({{ item.birthDate | date }})</a>
              <a v-else>{{ item.name }} (não cadastrado)</a>
            </template>
          </dx-autocomplete>
          <template v-if="$v.form.patient.id.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.id.required">Campo obrigatório</div>
          </template>
        </div>

        <!-- Birthdate -->
        <div class="column col-4 form-group"
             :class="{'has-error': $v.form.patient.birthDate.$error}">
          <label class="form-label">Nascimento</label>
          <div class="input-group">
            <dx-input-date
              id="appointment-patient-birth-date"
              class="form-input"
              v-model="form.patient.birthDate"
              :readonly="!!form.patient.id"
              @blur="$v.form.patient.birthDate.$touch()"
            />
            <span class="input-group-addon" v-if="dateOld">{{ dateOld }}</span>
          </div>
          <template v-if="$v.form.patient.birthDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.birthDate.date">Data inválida</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.patient.birthDate.maxDate">
              Data não pode ser superior a hoje
            </div>
          </template>
        </div>

        <!-- Phone -->
        <div class="column col-2 form-group"
             :class="{'has-error': $v.form.patient.cellphone.$error}">
          <label for="appointment-patient-phone" class="form-label">Telefone</label>
          <input type="text" id="appointment-patient-phone"
                 class="form-input" placeholder="(00) 0 0000-0000"
                 v-model="form.patient.cellphone"
                 @blur="$v.form.patient.cellphone.$touch()"
                 v-mask-phone.br>
          <template v-if="$v.form.patient.cellphone.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.cellphone.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.patient.cellphone.phone">Telefone inválido</div>
          </template>
        </div>
      </div>
      <div class="columns">
        <!-- Place -->
        <div class="column col-3 form-group">
          <label class="form-label">Posto</label>
          <input
            type="text"
            class="form-input"
            :value="place ? place.name : '--'"
            tabindex="-1"
            readonly
          >
        </div>

        <!-- Bed -->
        <div class="column col-3 form-group">
          <label class="form-label">Leito</label>
          <input
            type="text"
            class="form-input"
            :value="bed ? bed.name : '--'"
            tabindex="-1"
            readonly
          >
        </div>

        <!-- Bed type -->
        <div class="column col-3 form-group">
          <label class="form-label">Acomodação</label>
          <input
            type="text"
            class="form-input"
            :value="accommodation ? accommodation.name : '--'"
            tabindex="-1"
            readonly
          >
        </div>

        <!-- Insurance -->
        <div class="column col-3 form-group"
             :class="{'has-error': $v.form.insurance.plan.id.$error}">
          <label for="appointment-insurance" class="form-label">Convênio</label>
          <div :class="hasExpenses ? 'tooltip' : ''"
               data-tooltip="Existem procedimentos para este convênio">
            <select id="appointment-insurance" class="form-select"
                    v-model="form.insurance.plan.id" :disabled="hasExpenses"
                    @blur="$v.form.insurance.plan.id.$touch()">
              <option value="">[Selecione o convênio]</option>
              <option v-for="(item, i) in availableInsurances"
                      :value="item.plan.id" :key="i">{{ item.customName }}</option>
            </select>
          </div>
          <template v-if="$v.form.insurance.plan.id.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.insurance.plan.id.required">Campo obrigatório</div>
          </template>
        </div>
      </div>
      <div class="columns" v-if="info.length > 0">
        <!-- Info -->
        <div class="column col-12">
          <div class="has-error-message" style="width: 100%">
            <strong>
              <fa-icon :icon="['fal', 'clock']" style="margin-right: 2px; margin-top: 2.8px;" />
              <span>{{ info }}</span>
            </strong>
          </div>
        </div>
      </div>
      <div class="columns">
        <!-- Notes -->
        <div class="column col-12">
          <label for="appointment-notes" class="form-label">Anotações</label>
          <textarea id="appointment-notes" class="form-input" rows="4" v-model="form.notes" />
        </div>
      </div>
    </form>

    <dx-modal
      title="Lista de pacientes internados"
      v-model="patientsModal.show" size="md"
      v-if="patientsModal.show"
    >
      <div
        class="empty mt-2"
        v-if="patientsModal.items.length === 0 && !patientsModal.loading"
      >
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
        </div>
        <p class="empty-title h6">Pacientes internados</p>
        <p class="empty-subtitle">
          Nenhum paciente internado foi encontrado!
        </p>
      </div>
      <div class="loading loading-lg mt-2" v-else-if="patientsModal.loading"></div>
      <table class="table table-hover expense-modal-table" v-else>
        <thead>
        <tr>
          <th>Nome</th>
          <th>Nascimento</th>
          <th>CPF</th>
          <th>Internado em</th>
        </tr>
        </thead>
        <tbody>
        <tr
          class="c-hand"
          v-for="item in patientsModal.items"
          :key="item.id"
          @click="setPatient(item)"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.birthDate | date }}</td>
          <td>
            <span v-if="item.identity">{{ item.identity.value | cpf }}</span>
            <span v-else>-</span>
          </td>
          <td>
            <span
              v-if="item.patient && item.patient.hospitalization"
            >{{ item.patient.hospitalization.date | date }}</span>
            <span v-else>-</span>
          </td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="patientsModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { required } from 'vuelidate/src/validators';
import {
  date,
  phone,
  hour,
  maxDate,
  minDate,
} from 'src/data/validators';
import { APPOINTMENT_NO_SCHEDULES } from '@/data/contants';
import formMixin from '@/mixins/form';
import * as type from '@/data/appointment-types';
import appointmentStatuses, { getColor } from '@/data/appointment-statuses';
import { mergeFrom } from '@/helpers/object';
import dateOld from '@/filters/date-old';
import moment from 'moment';

export default {
  mixins: [formMixin],
  data() {
    return {
      statuses: appointmentStatuses,
      type,
      patient: null,
      hasDifferentInsurance: false,
      info: '',
      planSettings: this.blankFormPlanSettings(),
      form: this.$store.state.surgeryAppointment.form,
      noSchedulesLabel: APPOINTMENT_NO_SCHEDULES,
      patientsModal: {
        show: false,
        items: [],
        loading: false,
      },
      hospitalization: this.blankHospitalizationData(),
    };
  },
  mounted() {
    this.init();
  },
  validations() {
    return {
      hour: { required, hour },
      form: {
        scheduleId: { required },
        professional: {
          id: { required },
        },
        patient: {
          id: { required },
          birthDate: { date, maxDate: maxDate() },
          cellphone: { required, phone },
          insurance: {},
        },
        insurance: {
          record: {},
          validity: {
            date,
            minDate: minDate(moment(this.form.startDate).format('YYYY-MM-DD')),
          },
          plan: {
            id: { required },
          },
        },
        type: { required },
      },
    };
  },
  watch: {
    'form.patient.name': {
      handler() {
        this.patient = {
          id: this.form.patient.id,
          name: this.form.patient.name,
        };
      },
    },
    'form.scheduleId': function watchScheduleId() {
      this.updateAvailableData();
    },
    'form.insurance.plan.id': function findInsuranceId() {
      const insurance = this.availableInsurances
        .find(({ plan }) => plan.id === this.form.insurance.plan.id);
      this.form.insurance.id = insurance ? insurance.id : '';
    },
  },
  computed: {
    ...mapState({
      appointmentState: state => state.surgeryAppointment,
      schedules: state => state.surgeryAppointment.schedules,
    }),
    ...mapGetters({
      getProfessionalById: 'surgeryGetProfessionalById',
      getInsuranceByPlanId: 'surgeryGetInsuranceByPlanId',
    }),
    isPatientNotRegistered() {
      return !!this.form.id && !this.form.patient.id && !!this.form.patient.name;
    },
    isReadonly() {
      return !this.form.slot || !!this.form.id;
    },
    hasExpenses() {
      return this.form.expenses && this.form.expenses.length > 0;
    },
    hour: {
      get() {
        return this.form.startDate.split('T')[1];
      },
      set(value) {
        this.form.startDate = [
          this.form.startDate.split('T')[0],
          value,
        ].join('T');
      },
    },
    professionalSpecialtyKey: {
      get() {
        if (this.form.professional.id) {
          return [
            this.form.professional.id,
            this.form.professional.specialty || '',
          ].join('-');
        }
        return '';
      },
      set(value) {
        [
          this.form.professional.id,
          this.form.professional.specialty,
        ] = value.split('-');
      },
    },
    dateOld() {
      return dateOld(this.form.patient.birthDate, true);
    },
    statusColor() {
      return getColor(this.form.status);
    },
    availableProfessionals() {
      return this.appointmentState.available.professionals
        .map(({ id, specialties }) => this.getProfessionalById(id, specialties));
    },
    availableProfessionalsSpecialty() {
      return this.availableProfessionals
        .reduce((result, professional) => {
          const specialties = professional.specialties.length > 0
            ? professional.specialties
            : [null];

          specialties.forEach((specialty) => {
            result.push({
              key: `${professional.id}-${specialty ? specialty.code : ''}`,
              id: professional.id,
              name: professional.name,
              council: professional.council,
              specialty,
            });
          });

          return result;
        }, []);
    },
    availableInsurances() {
      return this.appointmentState.available.insurancePlans
        .map(this.getInsuranceByPlanId);
    },
    place() {
      return this.hospitalization.place ? this.hospitalization.place : null;
    },
    bed() {
      return this.place ? this.place.bed : null;
    },
    accommodation() {
      return this.bed ? this.bed.accommodation : null;
    },
  },
  methods: {
    ...mapMutations({
      updateAvailableData: 'SurgeryAppointment.UPDATE_AVAILABLE_DATA',
    }),
    async init() {
      if (this.form.slot && !this.form.id) {
        this.updateAvailableData();
      }
      if (this.form.patient.name) {
        this.patient = {
          id: this.form.patient.id,
          name: this.form.patient.name,
        };
        await this.loadPatientHospitalization();
      }
      this.$nextTick(() => {
        if (!this.isReadonly) {
          const hourEl = document.getElementById('appointment-hour');
          hourEl.select();
          hourEl.focus();
        } else {
          document.getElementById('appointment-patient').focus();
        }
      });
    },
    validate() {
      this.$v.$touch();
      return !this.$v.$error;
    },
    setStatus({ key }) {
      this.form.status = key;
    },
    onPatientFocus() {
      // this.$v.form.patient.name.$reset();
    },
    onPatientBlur(text) {
      this.form.patient.name = text;
      // this.$v.form.patient.name.$touch();
    },
    async showHospitalizedPatients() {
      this.patientsModal.show = true;
      this.patientsModal.loading = true;
      try {
        const items = await this.findPatient('');
        this.patientsModal.items = items;
      } catch (e) {
        this.$toast.error(e);
      } finally {
        this.patientsModal.loading = false;
      }
    },
    findPatient(search) {
      const params = {
        notFilterBranch: true,
        hospitalized: true,
        search,
      };

      return this.$http.get('/patients', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
          //   return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    async setPatient(patient) {
      this.patientsModal.show = false;
      const mergeData = patient || this.$store.state.surgeryAppointment.blankForm().patient;
      mergeFrom(this.form.patient, mergeData);

      const address = patient && patient.addresses && patient.addresses.length > 0
        ? patient.addresses[0]
        : null;

      if (address) {
        this.form.patient.address = {
          id: address.id,
          type: address.type,
          postalCode: address.postalCode,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          neighborhood: address.neighborhood,
          city: address.city,
          state: address.state,
        };
      }

      if (patient && patient.id) {
        try {
          const { data } = await this.$http.get(`/patients/${patient.id}`);

          const planIsValid = this.availableInsurances
            .some(({ plan }) => plan.id === data.patient.insurance.planId);
          if (planIsValid) {
            this.form.insurance.id = data.patient.insurance.id;
            this.form.insurance.record = data.patient.insurance.record;
            this.form.insurance.validity = data.patient.insurance.validity;
            this.form.insurance.plan.id = data.patient.insurance.planId;
          } else {
            const insurance = this.availableInsurances
              .find(({ id }) => id === data.patient.insurance.id);
            if (insurance) {
              this.form.insurance.id = insurance.id;
              this.form.insurance.plan.id = insurance.plan.id;
            }
          }

          await this.loadHospitalization(data);
        } catch (e) {
          this.$toast.error(e);
        }
      }
    },
    unsetPatient() {
      mergeFrom(this.form.patient, this.$store.state.surgeryAppointment.blankForm().patient);
      this.info = '';
      this.patient = null;
      this.hospitalization = this.blankHospitalizationData();
    },
    updateInsurance() {
      this.form.patient.insurance = this.clone(this.form.insurance);
    },
    switchTab(id) {
      this.$emit('switch-tab', id);
    },
    async loadPatientHospitalization() {
      try {
        const { data } = await this.$http.get(`/patients/${this.form.patient.id}`);
        await this.loadHospitalization(data);
      } catch (e) {
        this.$toast.error(e);
      }
    },
    async loadHospitalization(patientData) {
      if (
        !patientData
        || !patientData.patient
        || !patientData.patient.hospitalization
        || !patientData.patient.hospitalization.id
      ) {
        return;
      }

      try {
        const { id } = patientData.patient.hospitalization;
        const { data } = await this.$http.get(`/hospitalizations/${id}`);
        this.hospitalization = data;
      } catch (e) {
        this.$toast.error(e);
      }
    },
    // async loadHospitalizationPlaces() {
    //   const params = {
    //     active: true,
    //     type: 'hospitalization',
    //   };
    //   const { data } = await this.$http.get('/places', { params });
    // },
    blankFormPlanSettings() {
      return {
        loaded: false,
        record: {
          required: false,
          requiredValidity: false,
          digits: {
            enabled: false,
            min: 0,
            max: 0,
          },
        },
      };
    },
    blankHospitalizationData() {
      return {
        id: null,
        place: {
          id: null,
          name: null,
          date: null,
          bed: {
            id: null,
            name: null,
            accommodation: {
              id: null,
              name: null,
            },
          },
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
@import 'src/assets/scss/colors';

.appointment-modal {
  .dropdown {
    & > a {
      display: block;
      margin-top: .05rem;
      &:focus {
        box-shadow: none;
      }
    }
    .tile-content {
      line-height: $line-height;
    }
  }
  .has-error-message {
    background-color: lighten($info-color, 35%);
    border: $info-color solid $border-width;
    border-radius: $border-radius;
    display: inline-flex;
    font-size: $font-size-xs;
    padding: $layout-spacing-sm;
  }
  .patient-not-registered {
    .form-input {
      border-color: $orange-color-500;
    }
    .btn {
      background-color: $orange-color-500;
      border-color: $orange-color-500;
      color: $light-color
    }
  }
}
</style>
