<template>
  <div v-if="isReady">
    <invoice-detail
      v-if="localId"
      :show="show"
      :id="localId"
      @close="close"
    />
    <invoice-create
      v-else
      :show="show"
      :value="value"
      :borrower="borrower"
      :ref-id="refId"
      @close="close"
    />
  </div>
</template>

<script>
import InvoiceCreate from './InvoiceCreate.vue';
import InvoiceDetail from './InvoiceDetail.vue';

export default {
  components: {
    InvoiceCreate,
    InvoiceDetail,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    borrower: {
      type: Object,
    },
    value: {
      type: Number,
      default: 0,
    },
    refId: {
      type: String,
    },
  },
  data() {
    return {
      ready: false,
      loading: false,
      localId: null,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    isReady() {
      return this.ready && !this.loading;
    },
  },
  methods: {
    async init() {
      if (this.id) {
        this.localId = this.id;
      } else if (this.refId) {
        this.localId = await this.findIdByRef();
      }
      this.ready = true;
    },
    async findIdByRef() {
      this.setLoading(true);

      try {
        const params = {
          limit: 1,
          refId: this.refId,
        };
        const { data } = await this.$http.get('/invoices', { params });

        if (data.items.length > 0) {
          return data.items[0].id;
        }
      } catch (e) {
        this.$toast.error(e);
      } finally {
        this.setLoading(false);
      }

      return null;
    },
    setLoading(value) {
      this.loading = value;
      this.$emit('loading', this.loading);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
