<template>
  <div class="appointments-surgery-content-page">
    <div class="page-subtoolbar p-2">
      <div class="columns">
        <div class="column col-2">
          <select
            class="form-select"
            id="filter-schedule-id"
            v-model="filter.scheduleId"
          >
            <option value="">[Selecione a sala]</option>
            <option
              v-for="schedule in filter.schedules"
              :value="schedule.id"
              :key="schedule.id"
            >{{ schedule.name }}</option>
          </select>
        </div>
        <div class="column">
          <st-date-nav-bar v-model="filter.date" />
        </div>
        <div class="column col-3">
          <div class="input-group">
            <input
              type="text"
              id="filter-search"
              class="form-input"
              v-model.trim="filter.search"
              placeholder="Pesquisar paciente..."
              @change="formatFilterSearch"
            >
            <button class="btn btn-warning btn-icon btn-action input-group-btn"
                    @click="filter.search = ''" v-if="filter.search !== ''">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
            <button class="btn btn-neutral btn-icon btn-action input-group-btn" v-else>
              <fa-icon :icon="['fal', 'search']"/>
            </button>
          </div>
        </div>
        <div class="column" style="flex: 0 0 3rem">
          <div class="btn-group btn-group-block">
            <button class="btn btn-gray btn-icon btn-block tooltip tooltip-bottom"
                    data-tooltip="Voltar para hoje"
                    @click="today">
              <fa-icon :icon="['fal', 'calendar-day']" size="3x"/>
            </button>
          </div>
        </div>
        <div class="column" style="flex: 0 0 9rem;">
          <div class="btn-group btn-group-block">
            <button class="btn btn-icon tooltip tooltip-bottom-left"
                    data-tooltip="Horários vagos"
                    :class="filter.hours === hoursFilter.FREE
                    ? 'btn-warning' : 'btn-gray'"
                    @click="filter.hours = hoursFilter.FREE">
              <fa-icon :icon="['fal', 'calendar-check']" size="3x"/>
            </button>
            <button class="btn btn-icon tooltip tooltip-bottom"
                    data-tooltip="Todos os horários"
                    :class="filter.hours === hoursFilter.ALL
                    ? 'btn-warning' : 'btn-gray'"
                    @click="filter.hours = hoursFilter.ALL">
              <fa-icon :icon="['fal', 'calendar-alt']" size="3x"/>
            </button>
            <button class="btn btn-icon tooltip tooltip-bottom-right"
                    data-tooltip="Horários ocupados"
                    :class="filter.hours === hoursFilter.TAKEN
                    ? 'btn-warning' : 'btn-gray'"
                    @click="filter.hours = hoursFilter.TAKEN">
              <fa-icon :icon="['fal', 'calendar-times']" size="3x"/>
            </button>
          </div>
        </div>
      </div>
    </div>

    <template v-if="!loading">
      <div v-if="isArrivalOrder" class="arrival-order-info">
        <fa-icon :icon="['fal', 'clock']" />
        <span>Agenda por ordem de chegada</span>
        <strong class="chip ml-2 bg-info">
          {{ arrivalOrderRange.start }} - {{ arrivalOrderRange.end }}
        </strong>
      </div>

      <div class="empty" v-if="filteredAppointments.length === 0 && !isArrivalOrder">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'calendar-alt']" size="3x"/>
        </div>
        <p class="empty-title h5">Sem agenda</p>
        <p class="empty-subtitle">Não existe configuração de agenda para esta data</p>
      </div>
      <template v-else>
        <table class="table table-hover">
          <tbody>
          <table-row
            v-for="(item, i) in filteredAppointments"
            :data="item" :key="i"
          />
          </tbody>
        </table>
      </template>
    </template>
    <div class="loading loading-lg mt-2" v-else></div>
  </div>
</template>

<script>
import {
  mapActions, mapMutations, mapState, mapGetters,
} from 'vuex';
import AblyService from '@/services/ably';
import formMixin from 'src/mixins/form';
import fuzzy from 'fuzzy';
import moment from 'moment';
import StringMask from 'string-mask';
import * as status from 'src/data/appointment-statuses';
import * as types from 'src/data/appointment-types';
import TableRow from './TableRow.vue';

const hoursFilter = {
  ALL: 'all',
  FREE: 'free',
  TAKEN: 'taken',
  INVOICE: 'invoice',
};

export default {
  mixins: [formMixin],
  components: {
    TableRow,
  },
  data() {
    return {
      status,
      hoursFilter,
      appointment: {
        show: true,
      },
      filter: this.$store.state.surgeryAppointment.filter,
    };
  },
  computed: {
    ...mapState({
      appointments: state => state.surgeryAppointment.items,
      loading: state => state.surgeryAppointment.loading,
      user: ({ auth }) => auth.user,
    }),
    ...mapGetters({
      hasClipboardData: 'surgeryHasClipboardData',
    }),
    isArrivalOrder() {
      return this.appointments
        .filter(item => item.status === 'available')
        .some(item => item.isArrivalOrder);
    },
    arrivalOrderRange() {
      const found = this.appointments
        .find(item => item.status === 'available' && item.isArrivalOrder);

      return {
        start: found.startDate.slice(-5),
        end: found.endDate.slice(-5),
      };
    },
    filteredAppointments() {
      let items = this.appointments.filter(item => (
        !(item.isArrivalOrder && item.status === 'available')
      ));

      if (this.filter.hours === hoursFilter.FREE) {
        items = this.appointments
          .filter(item => item.status === 'available');
      } else if (this.filter.hours === hoursFilter.TAKEN) {
        items = this.appointments
          .filter(item => item.patient && item.patient.name);
      } else if (this.filter.hours === hoursFilter.INVOICE) {
        items = this.appointments
          .filter(item => !item.account && item.patient);
      }

      const params = this.parseFilterSearchParams();

      if (params.value) {
        if (params.isHour) {
          items = items.filter((item) => {
            const hour = Number(item.date.substring(11, 16).replace(':', ''));
            switch (params.operator) {
              case '>': return hour >= params.value;
              case '<': return hour <= params.value;
              case '=': return hour === params.value;
              default: return true;
            }
          });
        } else {
          const results = fuzzy.filter(params.value, items, {
            extract: (el) => {
              if (el.patient && el.patient.name) {
                return this.removeAccentuation(el.patient.name);
              }
              return '';
            },
          });

          items = results.map(({ original }) => original);
        }
      }

      const fakeDate = '3000-01-01T00:00';
      return items.sort((a, b) => {
        if (a.startDate > b.startDate) return 1;
        if (a.startDate < b.startDate) return -1;

        if (!['waiting', 'scheduled'].includes(a.status)) return 1;
        if (!['waiting', 'scheduled'].includes(b.status)) return -1;

        if ((a.arrivedAt || fakeDate) > (b.arrivedAt || fakeDate)) return 1;
        if ((a.arrivedAt || fakeDate) < (b.arrivedAt || fakeDate)) return -1;
        return 0;
      });
    },
    eventsChannel() {
      return `${this.user.branch.id}-appts`;
    },
  },
  mounted() {
    this.filter.hours = hoursFilter.ALL;
    if ('s' in this.$route.query && this.$route.query.s) {
      this.filter.search = this.$route.query.s;
    }

    this.loadFilters();
    this.loadCalendar(false)
      .then(() => {
        this.startEvents();
        this.setWatchers();
      });
  },
  beforeDestroy() {
    this.stopEvents();
  },
  methods: {
    ...mapActions({
      loadCalendar: 'surgeryLoadCalendar',
      loadFilters: 'surgeryLoadFilters',
    }),
    ...mapMutations({
      upsert: 'SurgeryAppointment.UPSERT',
      remove: 'SurgeryAppointment.REMOVE',
      showSlotModal: 'SurgeryAppointment.SHOW_SLOT_MODAL',
    }),
    today() {
      this.filter.date = moment().startOf('day').toDate();
    },
    setWatchers() {
      this.$watch('filter.date', () => this.loadCalendar(false));
      this.$watch('filter.scheduleId', () => this.loadCalendar(true));
    },
    eventListener({ name, data }) {
      if (name === 'delete') {
        this.remove(data);
      } else if (moment(data.startDate).isSame(this.filter.date, 'day')) {
        if (this.appointments.length > 0) {
          this.upsert(data);
        } else {
          this.loadCalendar();
        }
      }
    },
    startEvents() {
      const { realtime } = AblyService.instance(this.user.id);
      const channel = realtime.channels.get(this.eventsChannel);
      channel.subscribe(this.eventListener);
    },
    stopEvents() {
      const { realtime } = AblyService.instance(this.user.id);
      const channel = realtime.channels.get(this.eventsChannel);
      channel.unsubscribe(this.eventListener);
    },
    openSlotModal() {
      if (this.hasClipboardData) {
        this.showSlotModal();
      }
    },
    formatFilterSearch() {
      const params = this.parseFilterSearchParams();

      if (params.isHour) {
        this.filter.search = params.operator + StringMask
          .apply(params.value.toString(), '90:00');
      }
    },
    parseFilterSearchParams() {
      const params = {
        value: this.filter.search,
        isHour: false,
        operator: null,
      };

      if (/^[><=]=?/.test(params.value)) {
        const match = params.value.match(/^([><=])(.+)/);
        if (match && match.length > 2) {
          params.isHour = true;
          // eslint-disable-next-line prefer-destructuring
          params.operator = match[1];
          params.value = Number(match[2].replace(/\D/g, ''));

          if (params.value <= 24) {
            params.value *= 100;
          } else if (params.value < 100) {
            params.value *= 10;
          }
        }
      } else {
        params.value = this.removeAccentuation(params.value);
      }
      return params;
    },
    formatDateTime(datetime) {
      if (datetime) return moment(datetime).format('DD/MM/YYYY HH:mm');
      return '';
    },
    getAppointmentType(type) {
      return types.getName(type);
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
.appointments-surgery-content-page {
  .page-toolbar {
    position: sticky;
    background-color: $gray-color-light;
    top: 0;
    @media screen and (max-width: $size-sm) {
    }
  }
  .page-subtoolbar {
    background-color: #eee;
    position: sticky;
    top: 0;
    .date-nav-bar {
      background-color: $light-color;
    }
    @media screen and (max-width: $size-sm) {
    }
  }
  .arrival-order-info {
    align-items: center;
    border: $border-width solid $border-color;
    color: $info-color;
    display: flex;
    padding: $layout-spacing;
    svg {
      font-size: .85rem;
      margin-right: $layout-spacing;
    }
    & + table {
      td {
        border-top: 0;
      }
    }
  }
  table {
    background-color: #fff;
    table-layout: fixed;
    tr {
      td {
        border: .05rem solid $border-color;
        &:first-child, &:last-child {
          border-left: 0;
        }
      }
    }
  }
}
</style>
