<template>
  <div class="appointments-page main-page">
    <portal to="page-name">Agenda</portal>

    <div class="main-page-side">
      <side-page/>
    </div>
    <div class="main-page-content">
      <content-page/>
    </div>
    <dx-modal :title="appointmentModalTitle"
              :value="showAppointmentModal" size="lg"
              @input="hideAppointmentModal" id="modal-appointment">
      <!-- Information -->
      <div class="has-error-message" v-if="info">
        <strong>
          <fa-icon class="mr-1" :icon="['fal', 'clock']" />
          <span>{{ info.message }}</span>
        </strong>
      </div>
      <st-tabs ref="appointment-tabs" @changed="loadData">
        <st-tab id="main" name="Agendamento">
          <appointment-modal
            ref="appointmentModal"
            @switch-tab="switchTab"
            @validateScheduleInfo="validateScheduleInfo"
            @patient-alert="patientAlert"
          />
        </st-tab>
        <st-tab id="expenses" name="Procedimentos" v-if="!isBlockedByAnotherUser">
          <expenses-modal
            :saving="saving"
            ref="expensesModal"
            @validateScheduleInfo="validateScheduleInfo"
            @check-appointment="checkAppointmentAndOpenAccount"
          />
        </st-tab>
        <st-tab id="patient" name="Paciente" v-if="!isBlockedByAnotherUser">
          <patient-modal
            ref="patientModal"
            @patient-alert="patientAlert"
          />
        </st-tab>
        <st-tab
          id="attachments"
          name="Anexos"
          v-if="form.id && form.patient.id && !isBlockedByAnotherUser"
        >
          <template v-if="showFiles">
            <st-entity-file-manager
              :entity-id="form.patient.id"
              :origin-id="form.id"
              origin-type="appointment"
              :key="form.id" />
          </template>
        </st-tab>
        <st-tab id="audit" name="Auditoria" v-if="form.id && !isBlockedByAnotherUser">
          <audit-modal v-if="currentTab === 'audit'" />
        </st-tab>
        <st-tab id="history" name="Outros" v-if="!isBlockedByAnotherUser">
          <template v-if="showHistory">
            <history-modal
              ref="historyModal"
              :patient-id="form.patient.id"
            />
          </template>
        </st-tab>
      </st-tabs>
      <template v-slot:footer>
        <template v-if="!isBlockedByAnotherUser">
          <button class="btn btn-neutral btn-icon btn-appointment float-left mr-2"
                  :class="{loading: replicate.loading, tooltip: !replicate.loading}"
                  :disabled="replicate.loading"
                  data-tooltip="Replicar agendamento"
                  @click="openReplicateModal"
                  v-if="!isFinished">
            <fa-icon :icon="['fal', 'clone']"/>
          </button>
          <button class="btn btn-neutral btn-icon btn-appointment float-left mr-2"
                  :class="{loading: document.loading, tooltip: !document.loading}"
                  :disabled="document.loading"
                  data-tooltip="Impressos"
                  @click="openDocumentModal">
            <fa-icon :icon="['fal', 'print']"/>
          </button>
          <dx-dropdown
            class="input-group-addon btn btn-neutral btn-appointment float-left mr-2"
            :class="{loading: printing, tooltip: !printing}"
            :items="[{ value: 'pdf', text: 'PDF' }, { value: 'whatsapp', text: 'WhatsApp' }]"
            :disabled="printing"
            data-tooltip="Protocolo"
            label="text"
            @select="setProtocol">
            <fa-icon :icon="['fal', 'file-alt']" v-if="!printing"/>
          </dx-dropdown>
          <router-link
            :to="asoCreateLink"
            class="btn btn-neutral btn-icon btn-appointment float-left mr-2 tooltip"
            data-tooltip="Emitir ASO"
            v-if="hasModule('occupational')"
          ><fa-icon :icon="['fal', 'user-hard-hat']"/></router-link>
          <a
            :href="whatsappLink"
            class="btn btn-neutral btn-icon btn-appointment tooltip mr-2 float-left"
            data-tooltip="Enviar mensagem"
            target="_blank"
            v-if="whatsappLink"
          ><fa-icon :icon="['fab', 'whatsapp']"></fa-icon></a>
          <a
            :href="whatsappPatientLink"
            class="btn btn-neutral btn-icon btn-appointment tooltip float-left"
            data-tooltip="Enviar link de cadastro para o paciente"
            target="_blank"
            v-if="whatsappPatientLink"
          ><fa-icon :icon="['fal', 'address-card']"></fa-icon></a>

          <button
            class="btn btn-gray mr-2"
            :class="{ loading: savingAction === 'waiting' }"
            :disabled="saving || form.status === 'payment'"
            @click="saveAndClose('waiting')"
            v-if="!isFinished"
          >Salvar e colocar em espera</button>

          <button
            class="btn btn-primary btn-icon btn-icon-left mr-2 tooltip"
            :class="{ loading: savingAction === 'main' }"
            :disabled="saving || isFinished"
            :data-tooltip="isFinished ? 'Finalizado pelo profissional' : 'Salvar agendamento'"
            @click="saveAndClose()"
          ><fa-icon :icon="['fal', isFinished ? 'lock' : 'save']"/>Salvar </button>
        </template>

        <button class="btn" @click="hideAppointmentModal">Sair</button>
      </template>
    </dx-modal>

    <search-modal :show="showSearchAppointmentModal"
                 v-if="showSearchAppointmentModal"
                 @close="hideSearchAppointmentModal" />

    <document-modal
      v-if="document.show"
      :show="document.show"
      :patient-id="form.patient.id"
      :appointment-id="form.id"
      @close="hideDocumentModal"
    />

    <replicate-modal
      v-if="replicate.show"
      :show="replicate.show"
      :id="form.id"
      :patient="form.patient"
      @close="hideReplicateModal"
    />

    <commitment-modal :show="showCommitmentAppointmentModal"
                 v-if="showCommitmentAppointmentModal"
                 @close="hideCommitmentAppointmentModal" />

    <print-modal :show="showPrintAppointmentModal"
                 v-if="showPrintAppointmentModal"
                 @close="hidePrintAppointmentModal" />

    <slot-modal :show="showSlotModal"
                 v-if="showSlotModal"
                 @close="hideSlotModal" />

    <confirmation-modal :show="confirmation.show"
                  v-if="confirmation.show"
                  :data="confirmation.data"
                  @close="confirmation.show = false" />

    <modal-patient-alert
      v-if="modalPatientAlert.show"
      :patient-id="modalPatientAlert.patientId"
      :patient-name="modalPatientAlert.patientName"
      :external-data="modalPatientAlert.data"
      :show="modalPatientAlert.show"
      @close="modalPatientAlert.show = false"
    />

    <dx-modal title="Protocolo via WhatsApp"
              id="modal-whatsapp-protocol"
              v-model="modalProtocolWhatsApp.show">
      <textarea id="notes" class="form-input" rows="22"
                v-model="modalProtocolWhatsApp.text"></textarea>
      <template slot="footer">
        <a
          :href="whatsappProtocol"
          class="btn btn-primary btn-icon btn-icon-left mr-1"
          target="_blank">
          <fa-icon :icon="['fab', 'whatsapp']" />Enviar
        </a>
        <button class="btn btn-gray" @click="modalProtocolWhatsApp.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import {
  mapActions,
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';
import moment from 'moment';
import { sanitize as sanitizeIdentity } from '@/helpers/indentity';
import { publicUrl } from '@/config';
import * as whatsapp from '@/helpers/whatsapp';
import { mergeFrom } from 'src/helpers/object';
// eslint-disable-next-line import/no-cycle
import { whatsappLink, formatPatientData } from '../helpers';
import AuditModal from './modals/Audit.vue';
import AppointmentModal from './modals/Appointment.vue';
import CommitmentModal from './modals/Commitment.vue';
import ConfirmationModal from './modals/Confirmation.vue';
import ContentPage from './ContentPage.vue';
import ExpensesModal from './modals/Expense.vue';
import HistoryModal from './modals/History.vue';
import PatientModal from './modals/Patient.vue';
import PrintModal from './modals/Print.vue';
import ReplicateModal from './modals/Replicate.vue';
import DocumentModal from './modals/Document.vue';
import SearchModal from './modals/Search.vue';
import SidePage from './SidePage.vue';
import SlotModal from './modals/Slot.vue';
import ModalPatientAlert from '../../patient/modals/Alert.vue';
import formMixin from '../../../mixins/form';

export default {
  mixins: [formMixin],
  components: {
    AuditModal,
    AppointmentModal,
    CommitmentModal,
    ConfirmationModal,
    ContentPage,
    DocumentModal,
    ExpensesModal,
    HistoryModal,
    ModalPatientAlert,
    PatientModal,
    PrintModal,
    ReplicateModal,
    SearchModal,
    SidePage,
    SlotModal,
  },
  data() {
    return {
      path: '/appointments',
      loading: false,
      printing: false,
      saving: false,
      savingAction: null,
      info: '',
      data: {
        items: [],
        total: 1,
        limit: 30,
        offset: 0,
      },
      document: {
        loading: false,
        show: false,
      },
      replicate: {
        loading: false,
        show: false,
      },
      confirmation: {
        show: false,
        data: {
          isExam: false,
          appointmentId: '',
          date: '',
          status: '',
          professionalName: '',
          patientName: '',
          insuranceName: '',
          expenses: [],
        },
      },
      modalPatientAlert: {
        show: false,
        patientId: '',
        patientName: '',
        data: null,
      },
      modalProtocolWhatsApp: {
        show: false,
        cellphone: '',
        text: '',
      },
      form: this.$store.state.appointment.form,
      needToSave: !this.$store.state.appointment.form.id,
      appointmentWatchers: [],
      currentTab: 'main',
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      showAppointmentModal: state => state.appointment.show,
      showSlotModal: state => state.appointment.slotShow,
      showCommitmentAppointmentModal: state => state.appointment.commitmentShow,
      showPrintAppointmentModal: state => state.appointment.printShow,
      showSearchAppointmentModal: state => state.appointment.searchShow,
      showFiles: state => state.appointment.showFiles,
      showHistory: state => state.appointment.showHistory,
    }),
    ...mapGetters({
      appointmentModalTitle: 'appointmentModalTitle',
      getProfessionalById: 'getProfessionalById',
      getInsuranceByPlanId: 'getInsuranceByPlanId',
      hasModule: 'hasModule',
      isBlockedByAnotherUser: 'isBlockedByAnotherUser',
    }),
    asoCreateLink() {
      return `/asos/create?appointmentId=${this.form.id}`;
    },
    whatsappLink() {
      return whatsappLink({
        status: this.form.status,
        date: this.form.startDate,
        patient: {
          phone: this.form.patient.cellphone,
          name: this.form.patient.name,
        },
        professional: {
          name: this.form.professional.name,
        },
      });
    },
    whatsappPatientLink() {
      if (!this.form.id) { return null; }

      const template = 'Olá [name]. '
        + 'Por favor, clique no link a seguir para revisar ou completar seu cadastro:\n\n'
        + '[link]';

      return whatsapp.link(this.form.patient.cellphone, template, {
        name: this.form.patient.name.split(' ')[0],
        link: `${publicUrl}/agendamento/${btoa(this.form.id)}/cadastro`,
      });
    },
    whatsappProtocol() {
      if (!this.modalProtocolWhatsApp.cellphone || !this.modalProtocolWhatsApp.text) {
        return null;
      }
      return `https://wa.me/+55${
        this.onlyNumbers(this.modalProtocolWhatsApp.cellphone)
      }?text=${encodeURIComponent(this.modalProtocolWhatsApp.text)}`;
    },
    isFinished() {
      const allowEditFinishedAppointment = this.user.branch.settings
          && this.user.branch.settings['clinical.allowEditFinishedAppointment']
        ? this.user.branch.settings['clinical.allowEditFinishedAppointment']
        : false;

      return !!this.form.service.finishedAt && !allowEditFinishedAppointment;
    },
  },
  watch: {
    showAppointmentModal(value) {
      if (value) {
        this.setAppointmentWatchers();
      } else {
        this.unsetAppointmentWatchers();
      }
    },
  },
  methods: {
    ...mapActions({
      hideAppointmentModal: 'closeAppointmentModal',
    }),
    ...mapMutations({
      upsertAppointment: 'Appointment.UPSERT',
      hideSlotModal: 'Appointment.HIDE_SLOT_MODAL',
      hideCommitmentAppointmentModal: 'Appointment.HIDE_COMMITMENT_APPOINTMENT_MODAL',
      hidePrintAppointmentModal: 'Appointment.HIDE_PRINT_APPOINTMENT_MODAL',
      hideSearchAppointmentModal: 'Appointment.HIDE_SEARCH_APPOINTMENT_MODAL',
    }),
    setAppointmentWatchers() {
      const setNeedToSave = () => {
        this.needToSave = true;
      };
      this.appointmentWatchers = [
        this.$watch('form.insurance.id', setNeedToSave),
        this.$watch('form.patient.id', setNeedToSave),
        this.$watch('form.expenses', setNeedToSave),
      ];
    },
    unsetAppointmentWatchers() {
      this.appointmentWatchers.forEach((unwatch) => {
        unwatch();
      });
      this.appointmentWatchers = [];
    },
    loadData({ tab }) {
      this.currentTab = tab.id;
      if (tab.id === 'expenses') {
        if (!this.isFinished) {
          this.$nextTick(() => {
            document.getElementById('expense-name').focus();
          });
        }
      } else if (tab.id === 'patient') {
        if (!this.form.patient.canSave) {
          this.form.patient.canSave = true;
          if (this.form.patient.id) {
            this.loadPatient(this.form.patient.id);
          }
        }
      } else if (tab.id === 'attachments') {
        this.$store.state.appointment.showFiles = true;
      } else if (tab.id === 'history') {
        this.$store.state.appointment.showHistory = true;
      }
    },
    async openDocumentModal() {
      this.document.loading = true;

      try {
        await this.checkAppointment({ checkPatient: true });
        this.document.show = true;
      } catch (e) {
        this.showError(e);
      }

      this.document.loading = false;
    },
    hideDocumentModal() {
      this.document.show = false;
    },
    async openReplicateModal() {
      this.replicate.loading = true;

      try {
        await this.checkAppointment({ checkPatient: false });
        this.replicate.show = true;
      } catch (e) {
        this.showError(e);
      }

      this.replicate.loading = false;
    },
    hideReplicateModal() {
      this.replicate.show = false;
    },
    validateAppointment() {
      if (!this.$refs.appointmentModal.validate()) {
        this.$refs['appointment-tabs'].selectTab('main');
        return false;
      }
      return true;
    },
    validatePatient() {
      if (!this.form.patient.canSave) {
        return true;
      }
      if (!this.$refs.patientModal.validate()) {
        this.$refs['appointment-tabs'].selectTab('patient');
        return false;
      }
      return true;
    },
    validate() {
      return this.validateAppointment() && this.validatePatient();
    },
    async saveAndClose(status = null) {
      if (this.isFinished) {
        return;
      }

      this.savingAction = 'main';
      if (status && typeof status === 'string') {
        this.form.status = status;
        this.savingAction = 'waiting';
      }

      try {
        await this.save();
        this.hideAppointmentModal();
        if (this.form.status === 'scheduled') {
          this.openConfirmationModal();
        }
      } catch (e) {
        this.showError(e);
      }

      this.savingAction = null;
    },
    async save(options = {}) {
      if (this.saving) {
        return null;
      }

      options = {
        checkAccount: true,
        ...options,
      };

      if (!this.validate()) {
        throw new Error('Existem campos com informações incorretas!');
      }

      this.saving = true;

      if (this.form.status === 'waiting') {
        this.form.arrivedAt = this.form.arrivedAt || moment().format('YYYY-MM-DDTHH:mm');
      } else if (
        !['in_attendance', 'finished', 'report', 'payment', 'screening']
          .includes(this.form.status)
      ) {
        this.form.arrivedAt = null;
      }

      if (
        options.checkAccount
        && ['waiting', 'finished', 'in_attendance', 'screening', 'report']
          .includes(this.form.status)
        && this.user.branch.settings
        && this.user.branch.settings['clinical.blockServiceWithoutAccount']
      ) {
        const hasAccount = this.form.expenses.length > 0
          && this.form.expenses.every(({ accountId }) => accountId);

        if (!hasAccount) {
          this.$refs['appointment-tabs'].selectTab('expenses');
          this.saving = false;
          this.savingAction = null;
          throw new Error('Você precisa finalizar o atendimento!');
        }
      }

      const profession = this.form.patient.profession
        && this.form.patient.profession.code
        ? this.form.patient.profession
        : null;

      const formData = {
        scheduleId: this.form.scheduleId,
        startDate: this.form.startDate,
        endDate: this.form.endDate || this.form.startDate,
        professional: this.form.professional,
        patient: {
          canSave: this.form.patient.canSave,
          id: this.form.patient.id,
          identity: sanitizeIdentity(this.form.patient.identity),
          secondaryIdentity: sanitizeIdentity(this.form.patient.secondaryIdentity),
          name: this.form.patient.name,
          socialName: this.form.patient.socialName,
          birthDate: this.form.patient.birthDate,
          gender: this.form.patient.gender,
          civilStatus: this.form.patient.civilStatus,
          profession,
          phone: this.form.patient.phone,
          cellphone: this.form.patient.cellphone,
          email: this.form.patient.email,
          address: this.form.patient.address,
          notes: this.form.patient.notes,
          alert: this.form.patient.alert,
          cns: this.form.patient.cns ? this.form.patient.cns : null,
          insurance: {
            id: this.form.patient.insurance.id,
            record: this.form.patient.insurance.record
              ? this.form.patient.insurance.record.toUpperCase()
              : this.form.patient.insurance.record,
            validity: this.form.patient.insurance.validity,
            planId: this.form.patient.insurance.plan.id,
          },
          motherName: this.form.patient.motherName ? this.form.patient.motherName : null,
          fatherName: this.form.patient.fatherName ? this.form.patient.fatherName : null,
        },
        insurance: {
          id: this.form.insurance.id,
          record: this.form.insurance.record
            ? this.form.insurance.record.toUpperCase()
            : '',
          validity: this.form.insurance.validity,
          planId: this.form.insurance.plan.id,
        },
        status: this.form.status,
        expenses: this.form.expenses,
        arrivedAt: this.form.arrivedAt,
        notes: this.form.notes,
        slot: this.form.slot,
        reserves: this.form.reserves,
        indicated: this.form.indicated,
        asoClient: null,
        type: this.form.type,
        markerId: this.form.markerId || null,
        placeId: this.form.placeId || null,
      };

      if (this.form.asoClient && this.form.asoClient.id) {
        formData.asoClient = this.form.asoClient;
      }

      const params = {
        toCalendar: true,
      };

      const req = this.form.id
        ? this.$httpX.put(`/appointments/${this.form.id}`, formData, { params })
          .then(({ data }) => ({ data, type: 'update' }))
        : this.$httpX.post('/appointments', formData, { params })
          .then(({ data }) => ({ data, type: 'create' }));

      return req
        .then(({ data }) => {
          this.upsertAppointment(data);
          this.form.id = data.id;
          this.form.patient.id = data.patient.id || null;
          if (this.form.patient.image.file && data.patient && data.patient.id) {
            const formImageData = new FormData();
            formImageData.append('file', this.form.patient.image.file);
            this.$httpX.post(`/entities/${data.patient.id}/images`, formImageData)
              .catch(() => {});
          }
          this.needToSave = false;
          return data;
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.reason) {
            const { reason } = e.response.data;
            if (reason === 'invalidExpense') {
              this.$refs['appointment-tabs'].selectTab('expenses');
              throw new Error('Procedimento desvinculado do convênio');
            } else if (reason === 'quantityOfSlotsUnavailable') {
              throw new Error('A quantidade de horários não está disponível');
            }
          }
          throw e;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    async loadPatient(id) {
      return this.$httpX.get(`/patients/${id}`)
        .then(({ data }) => {
          this.form.patient = mergeFrom(
            this.form.patient,
            formatPatientData(data),
          );
        })
        .catch(() => {
          this.showError(new Error('Erro ao carregar dados do paciente'));
        });
    },
    async checkAppointmentAndOpenAccount() {
      try {
        await this.checkAppointment({
          checkPatient: true,
          checkAccount: false,
        });
        await this.$refs.expensesModal.openAccount();
      } catch (e) {
        this.showError(e);
      }
    },
    async checkAppointment(options = {}) {
      options = {
        checkPatient: true,
        checkAccount: false,
        ...options,
      };

      const checkPatient = () => (
        !options.checkPatient || !!this.form.patient.id
      );

      if (this.needToSave || !this.form.id || !checkPatient()) {
        await this.save({ checkAccount: options.checkAccount });
      }

      if (!checkPatient()) {
        this.$refs['appointment-tabs'].selectTab('patient');
        throw new Error('Paciente não cadastrado');
      }
    },
    switchTab(id) {
      this.$refs['appointment-tabs'].selectTab(id);
    },
    patientAlert(data) {
      const params = {
        displayTo: data.displayTo,
      };

      this.$httpX.get(`/patients/${data.patientId}/alerts`, { params })
        .then(({ data: result }) => {
          if (result && result.items && result.items.length > 0) {
            this.modalPatientAlert.data = result;
            this.modalPatientAlert.patientId = data.patientId;
            this.modalPatientAlert.patientName = data.patientName;
            this.modalPatientAlert.show = true;
          }
        })
        .catch(() => {});
    },
    openConfirmationModal() {
      const professional = this.getProfessionalById(this.form.professional.id);
      const insurance = this.getInsuranceByPlanId(this.form.insurance.plan.id);
      this.confirmation.data = {
        isExam: this.form.type === 'exam',
        appointmentId: this.form.id,
        date: this.form.startDate,
        status: this.form.status,
        professionalName: professional.name,
        patientName: this.form.patient.name,
        insuranceName: insurance.customName,
        expenses: this.form.expenses.map(expenseItem => ({
          code: expenseItem.code,
          name: expenseItem.name,
          quantity: expenseItem.quantity,
          value: expenseItem.value,
        })),
        whatsappPatientLink: this.whatsappPatientLink,
      };

      this.confirmation.show = true;
    },
    setProtocol({ value }) {
      if (value === 'pdf') {
        this.printProtocol();
      } else {
        this.loadProtocolData();
      }
    },
    loadProtocolData() {
      return this.$http.get(`/appointments/${this.form.id}/show-protocol`)
        .then(({ data }) => {
          this.modalProtocolWhatsApp.cellphone = data.patient.cellphone;
          this.modalProtocolWhatsApp.text = `Olá ${data.patient.name.split(' ')[0]},\n`
            + `sou a secretária virtual da ${data.branch.name}. 🙋\n`
            + '\n'
            + 'Estou entrando em contato para lhe informar que seus exames '
            + `estarão disponíveis a partir do dia *${data.deliveryDate}*.\n`
            + '\n'
            + 'Exame(s):\n'
            + `${data.expenses.map(({ name }) => `- ${name}`).join('\n')}`
            + '\n'
            + '\n'
            + 'Informações para retirada online:\n'
            + `Link de acesso: *${data.branch.site}*\n`
            + `Protocolo: *${data.prefix}*\n`
            + `Senha: *${data.password}*\n`
            + '\n'
            + `${data.branch.name}\n`
            + `📱${data.branch.cellphone || data.branch.phone}\n`
            + `📍${this.formatAddress(data.branch.address)}`;
          this.modalProtocolWhatsApp.show = true;
        })
        .catch(() => {});
    },
    formatAddress(address) {
      return `${address.addressLine1} ${address.addressLine2}, ${
        address.neighborhood}, ${address.city}/${address.state}`;
    },
    async printProtocol() {
      this.printing = true;

      try {
        await this.checkAppointment({ checkPatient: true });
        await this.$file.print(`/appointments/${this.form.id}/print-protocols`);
      } catch (e) {
        this.showError(e);
      }

      this.printing = false;
    },
    validateScheduleInfo() {
      this.info = null;
      if (this.form.patient.id
        && this.form.professional.id
        && this.form.insurance.plan.id
        && this.form.expenses.length > 0) {
        const expense = this.form.expenses.find(({ type }) => type === 'consultation');

        if (expense) {
          const params = {
            professionalId: this.form.professional.id,
            patientId: this.form.patient.id,
            insuranceId: this.form.insurance.id,
            planId: this.form.insurance.plan.id,
            startDate: this.form.startDate,
            expenseId: expense.id,
            expenseType: expense.type,
            expenseReturn: expense.return || false,
          };

          if (this.form.id) {
            params.appointmentId = this.form.id;
          }

          this.$httpX.get('/appointments/info', { params })
            .then(({ data }) => {
              if (data) {
                this.info = data;
              }
            });
        }
      }
    },
    showError(e) {
      let errorMessage = 'Erro não especificado';

      if (e) {
        if (e instanceof Error) {
          errorMessage = e.message;
        } else if (typeof e === 'string') {
          errorMessage = e;
        } else if (e.response && e.response.data && e.response.data.message) {
          e = e.response.data.message;
        }
      }

      this.$toast.show(errorMessage, { type: 'error', timestamp: 7000 });
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/_variables.scss';
  @import 'src/assets/scss/mixins';
  .appointments-page {
    background-color: $light-color;
    height: 100%;
    &.main-page {
      display: flex;
      .main-page-side, .main-page-content {
        padding: 0;
      }
      .main-page-side {
        flex: 0 0 13rem;
      }
      .main-page-content {
        overflow-y: auto;
        flex: 1 0;
      }
    }
  }
  #modal-appointment {
    .modal-body {
      min-height: 332px;
    }
    .btn-appointment {
      width: 1.8rem;
      a {
        color: $gray-color;
      }
      .menu {
        color: #0C7C5D;
        a {
          color: $gray-color-dark;
          &:hover {
            color: $light-color;
          }
        }
      }
    }
    .has-error-message {
      background-color: lighten($secondary-color, 45%);
      border: $secondary-color solid $border-width;
      border-radius: $border-radius;
      display: inline-flex;
      font-size: $font-size-xs;
      padding: $layout-spacing-sm $layout-spacing;
      width: 100%;
    }
  }
</style>
