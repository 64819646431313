import moment from 'moment';
import addressFilter from '@/filters/address';
import { CPF, RG } from '@/data/identity-types';
import store from '@/store';
import {
  WHATSAPP_APPOINTMENT_CREATION,
  WHATSAPP_WEB_APPOINTMENT_CONFIRMATION,
} from '@/data/message-templates';

const ALLOWED_STATUSES = ['new', 'scheduled', 'confirmed', 'rescheduled'];

export function dateToCalendar(date) {
  return moment(date)
    .calendar(null, {
      nextWeek: 'dddd, DD/MM [às] HH:mm',
      sameElse: 'DD/MM/YYYY [às] HH:mm',
    })
    .toLowerCase()
    .replace('-feira', '')
    .replace(' às 00:00', '');
}

export function whatsappLink(data) {
  if (
    !ALLOWED_STATUSES.includes(data.status)
    || !data.patient.phone
    || moment().isSameOrAfter(data.date)
  ) {
    return null;
  }

  const { branch } = store.state.user;

  let template = '[patient_name],\n'
    + 'Agendamento para *[date_hour]* com o(a) profissional *[professional_name]*!\n\n'
    + '[branch_name]\n'
    + '[branch_address]';

  if (data.status === 'scheduled') {
    template = branch.settings.whatsappWeb.appointmentConfirmationTemplate;
    if (!template) {
      template = WHATSAPP_APPOINTMENT_CREATION;
    }
  } else if (data.status === 'confirmed') {
    template = branch.settings.whatsappWeb.appointmentReminderTemplate;
    if (!template) {
      template = WHATSAPP_WEB_APPOINTMENT_CONFIRMATION;
    }
  }

  const phone = `55${data.patient.phone.replace(/\D/g, '')}`;

  const text = template
    .replace('[patient_name]', data.patient.name.split(' ')[0])
    .replace('[date_hour]', dateToCalendar(data.date))
    .replace('[professional_name]', data.professional.name)
    .replace('[appointment_link]', '')
    .replace('[facility_name]', branch.name)
    .replace('[facility_address]', addressFilter(branch.address, 'full'));

  return `https://wa.me/${phone}?text=${encodeURIComponent(text)}`;
}

export function formatPatientData(data, options = {}) {
  options = {
    defaultPrimaryIdentityType: CPF,
    defaultSecondaryIdentityType: RG,
    ...options,
  };

  const address = data.addresses && data.addresses.length > 0
    ? data.addresses[0] : null;
  const patient = data.patient ? data.patient : null;
  const insurance = patient && patient.insurance ? patient.insurance : null;

  const formatIdentity = (identity, defaultType = '') => ({
    type: identity ? identity.type : defaultType,
    value: identity ? identity.value : '',
  });

  return {
    canSave: true,
    isIdentitySaved: !!data.identity && !!data.identity.value,
    id: data.id,
    name: data.name,
    socialName: data.socialName,
    birthDate: data.birthDate,
    gender: data.gender,
    identity: formatIdentity(data.identity, options.defaultPrimaryIdentityType),
    secondaryIdentity: formatIdentity(data.secondaryIdentity, options.defaultSecondaryIdentityType),
    civilStatus: data.civilStatus || '',
    profession: data.profession || { code: '', name: '' },
    phone: data.phone,
    cellphone: data.cellphone,
    email: data.email,
    insurance: {
      id: insurance ? insurance.id : '',
      name: '',
      record: insurance ? insurance.record : '',
      validity: insurance ? insurance.validity : '',
      plan: {
        id: insurance ? insurance.planId : '',
        name: '',
      },
    },
    address: {
      id: address ? address.id : '',
      type: address ? address.type : '',
      postalCode: address ? address.postalCode : '',
      addressLine1: address ? address.addressLine1 : '',
      addressLine2: address ? address.addressLine2 : '',
      neighborhood: address ? address.neighborhood : '',
      city: address ? address.city : '',
      state: address ? address.state : '',
    },
    notes: data.notes,
    alert: data.alert,
    cns: patient ? patient.cns : '',
    motherName: patient ? patient.motherName : '',
    fatherName: patient ? patient.fatherName : '',
    imageUrl: data.imageUrl,
    image: {
      file: null,
      preview: null,
      showOptions: false,
      showWebCam: false,
    },
  };
}
