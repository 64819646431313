<template>
  <tr class="table-row" :class="classes">
    <td class="text-center" width="60px">
      <strong>{{ data.startDate | date('HH:mm') }}</strong>
      <div v-if="origin || metaBundle" class="appointment-meta">
        <span
          v-if="origin"
          class="appointment-origin text-info tooltip tooltip-right"
          :data-tooltip="origin.description"
        ><fa-icon :icon="origin.icon"></fa-icon></span>
        <small
          v-if="metaBundle"
          class="text-bold text-info tooltip tooltip-right"
          :class="{ 'ml-1': metaApi }"
          data-tooltip="Atendimentos"
        >{{ metaBundle }}</small>
      </div>
    </td>
    <td class="text-center" width="45px">
      <dx-dropdown :items="statuses" label="name"
                   class="input-group-addon appointment-status"
                   @select="setStatus"
                   :disabled="!canChangeStatus || !!isFinished"
                   :max-height="350">
        <span class="square c-hand" :title="statusName"
            :style="{backgroundColor: statusColor}"></span>
        <template v-slot:item="{ item }">
          <div class="tile tile-centered c-hand">
            <div class="tile-icon">
              <span class="square" :style="{backgroundColor: item.color}"></span>
            </div>
            <div class="tile-content">{{ item.name }}</div>
          </div>
        </template>
      </dx-dropdown>
      <div v-if="data.status === 'waiting' && data.arrivedAt">
        <small :class="setStatusColor()">
          {{ $timeDiff(data.arrivedAt, now, 'HH:mm') }}
        </small>
      </div>
    </td>
    <td style="width: 30%;" class="text-ellipsis"
        :class="data.status !== 'blocked' ? 'c-hand' : ''"
        @click="openAppointment(data)">
      <template v-if="data.patient">
        <div>
          <span>{{ data.patient.name }}</span>
          <span v-if="!data.patient.id"> (não cadastrado)</span><br>
          <small>{{ (data.patient.cellphone || data.patient.phone) | phone }}</small>
        </div>
        <small v-if="data.notes" class="text-warning">{{ data.notes }}</small>
      </template>
      <template v-else>
        <span class="text-gray" :class="isBlocked ? 'hover-link' : ''"
              @click="openBlockedDetail(data)">
          {{ label }}
        </span><br>
        <small class="text-info">{{ scheduleNotes }}</small>
      </template>
    </td>
    <td class="text-ellipsis">
      <template v-if="data.insurance">
        <span>{{ customInsuranceName(data.insurance) }}</span><br>
      </template>
      <template v-else>
        <span v-if="insuranceNames.length">
          {{ insuranceNames[0] }}
          <span
            v-if="insuranceNames.length > 1"
            @click="openModalInsuranceList(insuranceNames, scheduleName)"
            class="label text-secondary hover-link ml-1">
            +{{ insuranceNames.length -1 }}
          </span>
        </span>
      </template>
      <div v-for="(expense, i) in data.expenses.slice(0, 2)" :key="i">
        <fa-icon
          :class="expense.accountId ? 'text-success' : 'text-gray'"
          :icon="['fal', expense.accountId ? 'sack-dollar' : 'times-circle']" />
        <small class="text-info">
          {{ expense.return ? '[Retorno] ' : '' }}{{ expense.name }}
          <strong class="c-hand"
                  @click="openModalExpenses"
                  v-if="othersExpenses && i > 0">(+{{ othersExpenses }})</strong>
        </small>
      </div>
    </td>
    <td class="text-ellipsis">
      <span>{{ scheduleName }}</span><br>
      <small v-if="professionalNames.length">
        {{ professionalNames[0] }}
        <span
          v-if="professionalNames.length > 1"
          class="tooltip hover-link"
          :data-tooltip="professionalNames.slice(1).join('\n')">
          (+{{ professionalNames.length -1 }})
        </span>
      </small>
    </td>
    <td class="text-center" width="45px">
      <dx-dropdown :items="menuAppointmentOptions" label="name"
                   :disabled="menuAppointmentOptions.length === 0"
                   direction="right" class="appointment-actions" v-if="!waiting">
        <button class="btn btn-icon btn-action btn-link text-dark">
          <fa-icon :icon="['fal', 'bars']"/>
        </button>
        <template v-slot:item="{ item }">
          <div @click="menuAction(item, data)">
            <fa-icon class="ml-1" :icon="item.icon"/>
            <span class="ml-2">{{ item.name }}</span>
          </div>
        </template>
      </dx-dropdown>
      <div class="loading" v-else></div>
    </td>
  </tr>
</template>

<script>
import moment from 'moment';
import {
  mapActions,
  mapMutations,
  mapGetters,
  mapState,
} from 'vuex';
import appointmentStatuses,
{
  getColor,
  getName,
  STATUS_AVAILABLE,
  STATUS_BLOCKED,
} from 'src/data/appointment-statuses';
import { APPOINTMENT_NO_SCHEDULES } from '@/data/contants';
import * as council from 'src/data/council-types';
import * as menuOptions from 'src/data/appointment-menu-options';
import * as types from 'src/data/appointment-types';
import { customName as customInsuranceName } from 'src/helpers/insurance';

export default {
  props: {
    data: {},
  },
  data() {
    return {
      council,
      menuOptions,
      statuses: appointmentStatuses,
      types,
      customInsuranceName,
      saving: false,
      locking: false,
      unlocking: false,
      deleting: false,
    };
  },
  computed: {
    ...mapGetters([
      'getProfessionalById',
      'getScheduleById',
      'getInsuranceByPlanId',
    ]),
    ...mapState({
      now: ({ now }) => now,
      clipboard: ({ appointment }) => appointment.clipboard,
      user: state => state.auth.user,
    }),
    classes() {
      const items = [];
      if (this.data.status === STATUS_BLOCKED) {
        items.push('appointment-block');
      }
      if (this.isCanceled) {
        items.push('appointment-canceled');
      }
      return items;
    },
    isBlocked() {
      return this.data.status === STATUS_BLOCKED;
    },
    label() {
      if (this.data.status === STATUS_BLOCKED) {
        return this.data.refKey ? 'Horário reservado' : 'Horário bloqueado';
      }
      return 'Horário disponível';
    },
    schedule() {
      return this.getScheduleById(this.data.scheduleId);
    },
    scheduleName() {
      return this.schedule ? this.schedule.name : APPOINTMENT_NO_SCHEDULES;
    },
    scheduleNotes() {
      return this.data.schedule
        ? this.data.schedule.notes
        : this.data.notes;
    },
    professionals() {
      return this.data.available.professionals
        .map(({ id }) => this.getProfessionalById(id));
    },
    professionalNames() {
      return this.professionals.map(({ name }) => name);
    },
    insurances() {
      return this.data.available.insurancePlans
        .map(id => this.getInsuranceByPlanId(id));
    },
    insuranceNames() {
      return this.insurances
        .map(({ customName }) => customName)
        .sort();
    },
    menuAppointmentOptions() {
      const options = this.menuOptions.options
        .filter(option => (option === '-' ? [] : option.verify(this.data, this.clipboard)));
      if (options.length > 0 && options[0] === '-') {
        options.shift();
      }
      return options;
    },
    statusColor() {
      return getColor(this.data.status);
    },
    statusName() {
      return getName(this.data.status);
    },
    waiting() {
      return this.saving || this.locking || this.unlocking || this.deleting;
    },
    hasAccount() {
      return this.data.expenses.some(item => !!item.accountId);
    },
    canChangeStatus() {
      return ![STATUS_BLOCKED, STATUS_AVAILABLE].includes(this.data.status);
    },
    canOpenAppointment() {
      return ![STATUS_BLOCKED].includes(this.data.status);
    },
    hasMeta() {
      return !!this.data.meta;
    },
    metaBundle() {
      return this.hasMeta && 'bundle' in this.data.meta
        ? this.data.meta.bundle
        : null;
    },
    metaApi() {
      return this.hasMeta && 'api' in this.data.meta
        ? this.data.meta.api
        : null;
    },
    metaOrigin() {
      return this.hasMeta && 'origin' in this.data.meta
        ? this.data.meta.origin
        : null;
    },
    origin() {
      if (!this.metaOrigin && !this.metaApi) {
        return null;
      }

      const makeData = (icon, origin = null) => ({
        icon,
        description: `Agendamento externo (${origin})`,
      });

      switch (this.metaOrigin || 'api') {
        case 'app':
          return makeData(['far', 'mobile'], 'Aplicativo');
        case 'site':
          return makeData(['far', 'desktop'], 'Site');
        case 'call_center':
          return makeData(['far', 'headset'], 'Call center');
        default:
          return makeData(['far', 'at'], 'API');
      }
    },
    othersExpenses() {
      return this.data.expenses.length - 2;
    },
    isFinished() {
      const allowEditFinishedAppointment = this.user.branch.settings
      && this.user.branch.settings['clinical.allowEditFinishedAppointment']
        ? this.user.branch.settings['clinical.allowEditFinishedAppointment']
        : false;

      return !!this.data.service && this.data.service.finishedAt && !allowEditFinishedAppointment;
    },
    isCanceled() {
      return ['patient_canceled', 'professional_canceled'].includes(this.data.status);
    },
  },
  methods: {
    ...mapActions({
      showAppointmentModal: 'openAppointmentModal',
    }),
    ...mapMutations({
      copy: 'Appointment.COPY_APPOINTMENT',
      cut: 'Appointment.CUT_APPOINTMENT',
      clearClipboard: 'Appointment.CLEAR_CLIPBOARD',
    }),
    openAppointment(data) {
      if (!this.canOpenAppointment) {
        return;
      }
      this.showAppointmentModal(data);
    },
    setStatus({ key }) {
      if ((key === 'waiting'
        || key === 'finished'
        || key === 'in_attendance'
        || key === 'screening'
        || key === 'report')
        && this.user.branch.settings
        && this.user.branch.settings['clinical.blockServiceWithoutAccount']) {
        const hasAccount = !!this.data.expenses.find(({ accountId }) => accountId);
        if (!hasAccount) {
          this.$toast.show('É obrigatório finalizar uma conta', { type: 'error' });
          return;
        }
      }

      if (['patient_canceled', 'missed', 'rescheduled'].includes(key)) {
        this.$emit('infoWaitingList');
      }

      const params = {
        status: key,
      };

      if (key === 'waiting') {
        params.arrivedAt = moment().format('YYYY-MM-DDTHH:mm');
        this.data.arrivedAt = params.arrivedAt;
      } else if (!['in_attendance', 'finished', 'report', 'payment', 'screening'].includes(key)) {
        params.arrivedAt = null;
        this.data.arrivedAt = null;
      }

      this.updateAppointment(this.data.id, params)
        .catch(() => {});
      this.data.status = key;
    },
    setStatusColor() {
      const timeDiff = this.$timeDiff(this.data.arrivedAt, this.now, 'HH:mm');
      if (timeDiff > '00:09' && timeDiff < '00:20') {
        return 'text-error';
      }
      if (timeDiff > '00:20') {
        return 'text-error text-bold';
      }
      return 'text-dark';
    },
    openModalInsuranceList(insurances, scheduleName) {
      this.$emit('openModalInsuranceList', {
        scheduleName,
        insurances,
      });
    },
    openModalExpenses() {
      this.$emit('openModalExpenses', {
        expenses: this.data.expenses,
      });
    },
    menuAction(menu, data) {
      switch (menu.type) {
        case 'new':
        case 'edit': {
          this.showAppointmentModal(data);
          break;
        }
        case 'copy': {
          this.copy(data);
          break;
        }
        case 'cut': {
          this.menuCutAppointment(data);
          break;
        }
        case 'paste': {
          this.menuPasteAppointment(data);
          break;
        }
        case 'lock': {
          this.menuLockAppointment();
          break;
        }
        case 'unlock': {
          this.menuUnlockAppointment();
          break;
        }
        case 'delete': {
          this.menuDeleteAppointment();
          break;
        }
        default: {
          break;
        }
      }
    },
    menuCutAppointment(data) {
      if (this.hasAccount) {
        this.$toast.show('Agendamento vinculado a uma fatura', {
          type: 'error',
          timeout: 5000,
        });
        return;
      }
      this.cut(data);
    },
    menuPasteAppointment(data) {
      const {
        data: clipboardData,
        event: clipboardEvent,
      } = this.clipboard;

      const professional = data.available.professionals
        .find(({ id }) => id === clipboardData.professional.id)
        || data.available.professionals[0];

      const professionalData = {
        id: professional.id,
        specialty: undefined,
      };

      if (professional.specialties.length > 0) {
        if (clipboardData.professional.specialty) {
          professionalData.specialty = professional.specialties
            .find(specialty => specialty === clipboardData.professional.specialty);
        }

        if (!professionalData.specialty) {
          [professionalData.specialty] = professional.specialties;
        }
      }

      const request = clipboardEvent === 'cut'
        ? this.updateAppointment(clipboardData.id, {
          scheduleId: data.scheduleId,
          startDate: data.startDate,
          endDate: data.endDate,
          professional: professionalData,
          originalKey: clipboardData.key,
          status: 'scheduled',
          slot: false,
        })
        : this.createAppointment({
          scheduleId: data.scheduleId,
          startDate: data.startDate,
          endDate: data.endDate,
          professional: professionalData,
          patient: clipboardData.patient,
          insurance: {
            id: clipboardData.insurance.id,
            record: clipboardData.insurance.record,
            validity: clipboardData.insurance.validity,
            planId: clipboardData.insurance.plan.id,
          },
          type: data.type || clipboardData.type,
          status: 'scheduled',
          expenses: clipboardData.expenses,
          notes: clipboardData.notes,
        });

      request
        .then(() => {
          this.clearClipboard();
        })
        .catch(() => {});
    },
    async menuDeleteAppointment() {
      if (this.hasAccount) {
        this.$toast.show('Agendamento vinculado a uma fatura', {
          type: 'error',
          timeout: 5000,
        });
        return;
      }

      const params = {
        startDateTime: this.data.startDate,
      };
      if (this.data.patient.id) {
        params.patientId = this.data.patient.id;
      } else {
        params.patientName = this.data.patient.name;
      }

      let appointments = [];
      await this.$httpX.get('/appointments', { params })
        .then(({ data }) => {
          appointments = data.items.map((item) => {
            let hasAccount = false;
            if (item.expenses && item.expenses.length > 0) {
              item.expenses.forEach((expense) => {
                if (expense.accountId) {
                  hasAccount = true;
                }
              });
            }

            const appointment = {
              id: item.id,
              checked: item.id === this.data.id,
              startDate: item.startDate,
              patient: item.patient,
              professional: item.professional,
              schedule: item.schedule,
              insurance: item.insurance,
              status: item.status,
              type: item.type,
              hasAccount,
            };
            return appointment;
          });
        });

      if (appointments.length > 1) {
        this.$emit('deleteMultipleAppointments', appointments);
        return;
      }

      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este agendamento?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: close => this.deleteAppointment(this.data.id)
              .catch(() => {})
              .then(() => {
                close();
              }),
          },
        ],
      });
    },
    menuLockAppointment() {
      if (this.locking) {
        return;
      }

      let allow = true;
      if (this.user.branch
        && this.user.branch.customerId === '5ef4ade28b64ba627e469aab'
        && this.user.branch.roles
        && this.user.branch.roles.length > 0) {
        this.user.branch.roles.forEach(({ actions }) => {
          const found = actions.find(({ action }) => action === 'professional.*');
          if (found) {
            allow = false;
          }
        });
      }

      if (!allow) {
        this.$toast.show('Permissão negada!', { type: 'error' });
        return;
      }

      this.locking = true;

      const formData = {
        scheduleId: this.data.scheduleId,
        startDate: this.data.startDate,
        endDate: this.data.endDate,
        professional: this.data.available.professionals[0],
        type: this.data.type,
        status: STATUS_BLOCKED,
        notes: `Bloqueado por ${this.user.name} em ${moment().format('DD/MM/YYYY HH:mm')}`,
      };
      this.createAppointment(formData)
        .then(({ data }) => {
          this.data.id = data.id;
          this.data.status = STATUS_BLOCKED;
        })
        .catch(() => {})
        .then(() => {
          this.locking = false;
        });
    },
    menuUnlockAppointment() {
      if (this.unlocking) {
        return;
      }

      let allow = true;
      if (this.user.branch
        && this.user.branch.customerId === '5ef4ade28b64ba627e469aab'
        && this.user.branch.roles
        && this.user.branch.roles.length > 0) {
        this.user.branch.roles.forEach(({ actions }) => {
          const found = actions.find(({ action }) => action === 'professional.*');
          if (found) {
            allow = false;
          }
        });
      }

      if (!allow) {
        this.$toast.show('Permissão negada!', { type: 'error' });
        return;
      }

      this.unlocking = true;

      this.deleteAppointment(this.data.id)
        .then(() => {
          this.data.id = null;
          this.data.status = STATUS_AVAILABLE;
        })
        .catch(() => {})
        .then(() => {
          this.unlocking = false;
        });
    },
    createAppointment(data) {
      this.saving = true;
      return this.$httpX.post('/appointments', data)
        .finally(() => {
          this.saving = false;
        });
    },
    deleteAppointment(id) {
      this.deleting = true;
      return this.$httpX
        .delete(`/appointments/${id}`)
        .then(() => {
          this.$emit('infoWaitingList');
        })
        .catch(this.$toast.error)
        .finally(() => {
          this.deleting = false;
        });
    },
    updateAppointment(id, data) {
      this.saving = true;
      return this.$httpX.put(`/appointments/${id}`, data)
        .finally(() => {
          this.saving = false;
        });
    },
    openBlockedDetail(data) {
      if (data.status !== STATUS_BLOCKED) {
        return;
      }
      this.$notification.show('Informação', {
        type: 'gray',
        timeout: 10000,
        ellipsis: false,
        content: data.notes,
      });
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';

.table-row {
  .appointment-status {
    &.dropdown > a {
      display: block;
      margin-top: .05rem;
      &:focus {
        box-shadow: none;
      }
    }
    .tile-content {
      line-height: $line-height;
      text-align: left;
      white-space: nowrap;
    }
  }
  .appointment-actions {
    margin-top: .2rem;
    color: $dark-color;
    .menu {
      a {
        display: flex;
        .square {
          margin-right: $layout-spacing;
        }
      }
    }
    .menu-item {
      padding: $layout-spacing-sm;
      text-align: left;
      .divider {
        margin: 0;
      }
      &:hover {
        background-color: $secondary-color;
        border-radius: $border-radius;
        color: $light-color;
        cursor: pointer;
      }
    }
  }
  .appointment-meta {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: $border-width;
    .appointment-origin {
      display: block;
      svg {
        display: block;
      }
    }
  }

  &.appointment-block {
    background-color: #f0f1f4;
    color: darkgray;
  }
  &.appointment-canceled {
    background-color: #f0f1f4;
    strong {
      color: $error-color;
      text-decoration: line-through;
    }
  }
}
</style>
