<template>
  <div class="audit-modal">
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div v-else>
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Data</th>
          <th>Usuário</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(event, i) in events" :key="i">
          <td>{{ event.date | date('DD/MM/YYYY HH:mm') }}</td>
          <td>{{ getUserName(event.collaboratorId) }}</td>
          <td>{{ status.getName(event.status) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import * as status from 'src/data/appointment-statuses';
import * as type from '@/data/appointment-types';
import { capitalizeWord as capitalize } from '@/filters/capitalize';

export default {
  props: {
  },
  data() {
    return {
      status,
      type,
      events: [],
      collaborators: [],
      loading: false,
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    ...mapState({
      professionals: state => state.appointment.professionals,
      form: state => state.appointment.form,
    }),
  },
  methods: {
    async load() {
      this.loading = true;

      try {
        const { data } = await this.$http.get(`/appointments/${this.form.id}`);
        if (this.collaborators.length === 0) {
          this.collaborators = await this.getCollaborators();
        }
        this.events = data.events;
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
    async getCollaborators() {
      const params = {
        limit: 0,
      };

      const { data } = await this.$http.get('/collaborators', { params });
      return data.items;
    },
    getUserName(id) {
      const found = [...this.collaborators, ...this.professionals]
        .find(item => item.id === id);

      if (found) {
        return found.name;
      }

      return '-';
    },
    dateFromNow(date) {
      return capitalize(moment(date).fromNow());
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  .audit-modal {
  }
</style>
