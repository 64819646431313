<template>
  <div class="appointments-surgery-page main-page">
    <portal to="page-name">Agenda cirúrgica</portal>

    <div class="main-page-content">
      <content-page/>
    </div>

    <dx-modal :title="appointmentModalTitle"
              :value="showAppointmentModal" size="lg"
              @input="hideAppointmentModal" id="modal-surgery-appointment">
      <st-tabs ref="appointment-tabs" @changed="loadData">
        <st-tab id="main" name="Agendamento">
          <appointment-modal ref="appointmentModal" @switch-tab="switchTab"/>
        </st-tab>

        <st-tab id="procedures" name="Cirurgias">
          <surgery-modal></surgery-modal>
        </st-tab>

        <st-tab id="team" name="Equipe">
          <team-modal></team-modal>
        </st-tab>
        <st-tab id="surgery-description" name="Descrição cirúrgica">
          <div class="form-group mt-2">
            <select
              class="form-select"
              v-model="templateId"
              @change="setTemplate"
            >
              <option value="">[Selecione um modelo]</option>
              <option
                v-for="item in surgeryTemplates"
                :value="item.id"
                :key="item.id"
              >{{ item.name }}</option>
            </select>
          </div>
          <div class="form-group">
            <st-editor v-model="form.surgery.description"></st-editor>
          </div>
        </st-tab>
        <st-tab id="expense" name="Procedimentos">
          <expense-modal></expense-modal>
        </st-tab>
<!--        <st-tab id="prescription" name="Prescrição">-->
<!--          <div class="therapeutic-plan-group mt-2">-->
<!--            <div class="group">-->
<!--              <fa-icon :icon="['fal', 'info-circle']" class="mr-2"/>Nutrição-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="therapeutic-plan-group">-->
<!--            <div class="group">-->
<!--              <fa-icon :icon="['fal', 'info-circle']" class="mr-2"/>Soluções e Medicamentos-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="therapeutic-plan-group">-->
<!--            <div class="group">-->
<!--              <fa-icon :icon="['fal', 'info-circle']" class="mr-2"/>Recomendações-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="therapeutic-plan-group">-->
<!--            <div class="group">-->
<!--              <fa-icon :icon="['fal', 'info-circle']" class="mr-2"/>SAE / Plano de Cuidado-->
<!--            </div>-->
<!--          </div>-->
<!--        </st-tab>-->
      </st-tabs>

      <template v-slot:footer>
        <button class="btn btn-neutral btn-icon btn-appointment float-left mr-2"
                :class="{loading: document.loading, tooltip: !document.loading}"
                :disabled="document.loading"
                data-tooltip="Impressos"
                @click="openDocumentModal">
          <fa-icon :icon="['fal', 'print']"/>
        </button>
        <button class="btn btn-primary mr-2"
                :class="{loading: saving}"
                :disabled="saving || waitingSave"
                @click="save(null)">Salvar</button>
        <button class="btn" @click="hideAppointmentModal">Sair</button>
      </template>
    </dx-modal>

    <document-modal
      v-if="document.show"
      :show="document.show"
      :patient-id="form.patient.id"
      :appointment-id="form.id"
      @close="hideDocumentModal"
    />

    <print-modal :show="showPrintAppointmentModal"
                 v-if="showPrintAppointmentModal"
                 @close="hidePrintAppointmentModal" />

    <confirmation-modal :show="confirmation.show"
                  v-if="confirmation.show"
                  :data="confirmation.data"
                  @close="confirmation.show = false" />
  </div>
</template>

<script>
import {
  mapActions,
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';
import { mergeFrom } from 'src/helpers/object';
import moment from 'moment';
import {
  create as createAppointment,
  update as updateAppointment,
} from 'src/app/appointment/api';
import AppointmentModal from './modals/Appointment.vue';
import ConfirmationModal from './modals/Confirmation.vue';
import ContentPage from './ContentPage.vue';
import PrintModal from './modals/Print.vue';
import DocumentModal from './modals/Document.vue';
import SurgeryModal from './modals/Surgery.vue';
import TeamModal from './modals/Team.vue';
import ExpenseModal from './modals/Expense.vue';

export default {
  components: {
    AppointmentModal,
    ConfirmationModal,
    ContentPage,
    DocumentModal,
    PrintModal,
    SurgeryModal,
    TeamModal,
    ExpenseModal,
  },
  data() {
    return {
      path: '/appointments',
      loading: false,
      printing: false,
      saving: false,
      waitingSave: false,
      data: {
        items: [],
        total: 1,
        limit: 30,
        offset: 0,
      },
      document: {
        loading: false,
        show: false,
      },
      confirmation: {
        show: false,
        data: {
          isExam: false,
          appointmentId: '',
          date: '',
          status: '',
          professionalName: '',
          patientName: '',
          insuranceName: '',
          expenses: [],
        },
      },
      surgeryTemplates: [],
      form: this.$store.state.surgeryAppointment.form,
      templateId: '',
    };
  },
  mounted() {
    this.$http.get('/surgery-templates')
      .then(({ data }) => {
        this.surgeryTemplates = data.items;
      });
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      showAppointmentModal: state => state.surgeryAppointment.show,
      showPrintAppointmentModal: state => state.surgeryAppointment.printShow,
      showFiles: state => state.surgeryAppointment.showFiles,
      showHistory: state => state.surgeryAppointment.showHistory,
    }),
    ...mapGetters({
      appointmentModalTitle: 'surgeryAppointmentModalTitle',
      getProfessionalById: 'surgeryGetProfessionalById',
      getInsuranceByPlanId: 'surgeryGetInsuranceByPlanId',
    }),
  },
  methods: {
    setTemplate() {
      if (this.templateId) {
        const template = this.surgeryTemplates.find(({ id }) => id === this.templateId);
        if (template) {
          this.form.surgery.description = template.data;
        }
      }
    },
    load() {
      this.loading = true;
    },
    ...mapActions({
      loadCalendar: 'surgeryLoadCalendar',
    }),
    ...mapMutations({
      upsertAppointment: 'SurgeryAppointment.UPSERT',
      hideAppointmentModal: 'SurgeryAppointment.HIDE_APPOINTMENT_MODAL',
      hidePrintAppointmentModal: 'SurgeryAppointment.HIDE_PRINT_APPOINTMENT_MODAL',
    }),
    loadData({ tab }) {
      if (tab.id === 'patient') {
        if (!this.form.patient.canSave) {
          this.form.patient.canSave = true;
          if (this.form.patient.id) {
            this.loadPatient(this.form.patient.id);
          }
        }
      } else if (tab.id === 'attachments') {
        this.$store.state.surgeryAppointment.showFiles = true;
      } else if (tab.id === 'history') {
        this.$store.state.surgeryAppointment.showHistory = true;
      }
    },
    async openDocumentModal() {
      this.document.loading = true;

      await this.save(null, false)
        .then(() => {
          if (!this.form.patient.id) {
            this.$toast.show('Obrigatório cadastrar o paciente', { type: 'error' });
            return;
          }
          this.document.show = true;
        })
        .catch(() => {})
        .then(() => {
          this.document.loading = false;
        });
    },
    hideDocumentModal() {
      this.document.show = false;
    },
    validateAppointment() {
      if (!this.$refs.appointmentModal.validate()) {
        this.$refs['appointment-tabs'].selectTab('main');
        return false;
      }
      return true;
    },
    validatePatient() {
      if (!this.form.patient.canSave) {
        return true;
      }
      return true;
    },
    validate() {
      return this.validateAppointment() && this.validatePatient();
    },
    save(status = null, internal = true) {
      if (!this.validate()) {
        return internal ? Promise.resolve() : Promise.reject();
      }

      if (status && internal) {
        this.waitingSave = true;
      } else if (internal) {
        this.saving = true;
      }

      if (status !== null) {
        this.form.status = status;
      }

      if (this.form.status === 'waiting') {
        this.form.arrivedAt = this.form.arrivedAt || moment().format('YYYY-MM-DDTHH:mm');
      } else if (
        !['in_attendance', 'finished', 'report', 'payment', 'screening']
          .includes(this.form.status)
      ) {
        this.form.arrivedAt = null;
      }

      if (
        internal
        && ['waiting', 'finished', 'in_attendance', 'screening', 'report']
          .includes(this.form.status)
        && this.user.branch.settings
        && this.user.branch.settings['clinical.blockServiceWithoutAccount']
      ) {
        const hasAccount = this.form.expenses.some(({ accountId }) => accountId);
        if (!hasAccount) {
          this.$toast.show('É obrigatório registrar um atendimento', { type: 'error' });
          // this.$refs['appointment-tabs'].selectTab('expenses');
          this.saving = false;
          this.waitingSave = false;
          return null;
        }
      }

      const identity = this.form.patient.identity
        ? {
          type: this.form.patient.identity.type || 'cpf',
          value: this.form.patient.identity.value,
        } : null;

      const formData = {
        scheduleId: this.form.scheduleId,
        startDate: this.form.startDate,
        endDate: this.form.endDate || this.form.startDate,
        professional: this.form.professional,
        patient: {
          canSave: this.form.patient.canSave,
          id: this.form.patient.id,
          identity,
          name: this.form.patient.name,
          birthDate: this.form.patient.birthDate,
          gender: this.form.patient.gender,
          phone: this.form.patient.phone,
          cellphone: this.form.patient.cellphone,
          email: this.form.patient.email,
          address: this.form.patient.address,
          notes: this.form.patient.notes,
          cns: this.form.patient.cns ? this.form.patient.cns : null,
          insurance: {
            id: this.form.patient.insurance.id,
            record: this.form.patient.insurance.record
              ? this.form.patient.insurance.record.toUpperCase()
              : this.form.patient.insurance.record,
            validity: this.form.patient.insurance.validity,
            planId: this.form.patient.insurance.plan.id,
          },
        },
        insurance: {
          id: this.form.insurance.id,
          record: this.form.insurance.record
            ? this.form.insurance.record.toUpperCase()
            : '',
          validity: this.form.insurance.validity,
          planId: this.form.insurance.plan.id,
        },
        type: this.form.type,
        status: this.form.status,
        expenses: this.form.expenses,
        arrivedAt: this.form.arrivedAt,
        notes: this.form.notes,
        slot: this.form.slot,
        reserves: this.form.reserves,
        indicated: this.form.indicated,
        surgery: this.form.surgery,
      };

      const req = this.form.id
        ? updateAppointment(this.form.id, formData)
          .then(data => ({ data, type: 'update' }))
        : createAppointment(formData)
          .then(data => ({ data, type: 'create' }));

      return req
        .then(({ data }) => {
          this.upsertAppointment(data);
          this.form.id = data.id;
          this.form.patient.id = data.patient.id || null;
          if (this.form.patient.image.file && data.patient && data.patient.id) {
            const formImageData = new FormData();
            formImageData.append('file', this.form.patient.image.file);
            this.$http.post(`/entities/${data.patient.id}/images`, formImageData)
              .catch(() => {});
          }
          if (internal) {
            this.hideAppointmentModal();
            if (this.form.status === 'scheduled') {
              this.openConfirmationModal();
            }
          }
          return data;
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.reason) {
            const { reason } = e.response.data;
            if (reason === 'invalidExpense') {
              this.$refs['appointment-tabs'].selectTab('expenses');
              this.$toast.show('Procedimento desvinculado do convênio', { type: 'error' });
            } else if (reason === 'quantityOfSlotsUnavailable') {
              this.$toast.show('A quantidade de horários não está disponível', { type: 'error' });
            }
          }
          return internal ? Promise.resolve() : Promise.reject(e);
        })
        .finally(() => {
          this.waitingSave = false;
          this.saving = false;
        });
    },
    async loadPatient(id) {
      return this.$http.get(`/patients/${id}`)
        .then(({ data }) => {
          this.form.patient = mergeFrom(
            this.form.patient,
            this.formatEntity(data),
          );
        })
        .catch(() => {
          this.$toast.show('Erro ao carregar dados do paciente', { type: 'error' });
        });
    },
    checkAppointment() {
      let promise = Promise.resolve();

      if (!this.form.patient.id || !this.form.id) {
        this.form.patient.canSave = true;
        if (!this.validatePatient()) {
          this.$toast.show('Paciente não cadastrado', { type: 'warning', timeout: 3000 });
          return promise;
        }

        this.saving = true;
        promise = this.save(null, false);
      }

      return promise
        .then(() => {
          if (!this.form.patient.id) {
            this.$toast.show('Paciente com informações incompletas', {
              type: 'warning', timeout: 3000,
            });
            this.$refs['appointment-tabs'].selectTab('patient');
          } else {
            // this.$refs.expensesModal.openAccount();
          }
        })
        .catch(() => {
          this.$toast.show('Erro (conta)', { type: 'error' });
        });
    },
    formatEntity(data) {
      const address = data.addresses && data.addresses.length > 0
        ? data.addresses[0] : null;
      const patient = data.patient ? data.patient : null;
      const insurance = patient && patient.insurance ? patient.insurance : null;
      return {
        image: {
          file: null,
          preview: null,
          showOptions: false,
          showWebCam: false,
        },
        id: data.id,
        name: data.name,
        birthDate: data.birthDate,
        gender: data.gender,
        identity: data.identity ? data.identity : null,
        phone: data.phone,
        cellphone: data.cellphone,
        email: data.email,
        cns: data.patient.cns ? data.patient.cns : null,
        insurance: {
          id: insurance ? insurance.id : '',
          name: '',
          record: insurance ? insurance.record : '',
          validity: insurance ? insurance.validity : '',
          plan: {
            id: insurance ? insurance.planId : '',
            name: '',
          },
        },
        address: {
          id: address ? address.id : '',
          type: address ? address.type : '',
          postalCode: address ? address.postalCode : '',
          addressLine1: address ? address.addressLine1 : '',
          addressLine2: address ? address.addressLine2 : '',
          neighborhood: address ? address.neighborhood : '',
          city: address ? address.city : '',
          state: address ? address.state : '',
        },
        imageUrl: data.imageUrl,
        notes: data.notes,
      };
    },
    switchTab(id) {
      this.$refs['appointment-tabs'].selectTab(id);
    },
    openConfirmationModal() {
      const professional = this.getProfessionalById(this.form.professional.id);
      const insurance = this.getInsuranceByPlanId(this.form.insurance.plan.id);
      this.confirmation.data = {
        isExam: this.form.type === 'exam',
        appointmentId: this.form.id,
        date: this.form.startDate,
        status: this.form.status,
        professionalName: professional.name,
        patientName: this.form.patient.name,
        insuranceName: insurance.customName,
        expenses: this.form.expenses.map(expenseItem => ({
          code: expenseItem.code,
          name: expenseItem.name,
          quantity: expenseItem.quantity,
          value: expenseItem.value,
        })),
      };

      this.confirmation.show = true;
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/_variables.scss';
  @import 'src/assets/scss/mixins';
  .appointments-surgery-page {
    background-color: $light-color;
    height: 100%;
    &.main-page {
      display: flex;
      .main-page-side, .main-page-content {
        padding: 0;
      }
      .main-page-side {
        flex: 0 0 13rem;
      }
      .main-page-content {
        overflow-y: auto;
        flex: 1 0;
      }
    }
  }
  #modal-surgery-appointment {
    .modal-body {
      min-height: 332px;
    }
    .btn-appointment {
      width: 1.8rem;
    }
    .ck-content {
      height: 15rem;
    }
    .therapeutic-plan-group {
      .group {
        background-color: $gray-color-light;
        padding: $layout-spacing-sm;
      }
      padding: $layout-spacing-sm;
      table {
        margin-top: $layout-spacing-sm;
        td, th {
          padding: $layout-spacing-sm;
        }
      }
    }
  }
</style>
