export function parseTemplate(template, data = {}) {
  let text = template;
  Object.keys(data).forEach((key) => {
    text = text.replace(new RegExp(`\\[${key}]`, 'g'), data[key]);
  });
  return text;
}

export function link(phone, template, data = null) {
  if (!phone) return null;
  const number = `55${phone.replace(/\D/g, '')}`;
  const text = data ? parseTemplate(template, data) : template;
  return `https://wa.me/${number}?text=${encodeURIComponent(text)}`;
}
