<template>
  <div class="appointment-confirmation-modal">
    <dx-modal title="Confirmação de agendamento"
              :value="show" @input="close" id="modal-confirmation-list">
      <div class="columns card-group">
        <div class="column col-6">
          <small>Data e hora</small>
          <div class="card-value">{{ data.date | date('DD/MM/YYYY HH:mm') }}</div>
        </div>
        <div class="column col-6">
          <small>Status</small>
          <div class="card-value">{{ getName(data.status) }}</div>
        </div>
        <div class="column col-12 form-group divider" />
        <div class="column col-12">
          <small>Profissional</small>
          <div class="card-value">{{ data.professionalName }}</div>
        </div>
        <div class="column col-12 form-group divider" />
        <div class="column col-6">
          <small>Paciente</small>
          <div class="card-value">{{ data.patientName }}</div>
        </div>
        <div class="column col-6">
          <small>Convênio</small>
          <div class="card-value">{{ data.insuranceName }}</div>
        </div>
      </div>
      <div class="divider" />
      <div v-if="data.expenses && data.expenses.length > 0">
        <small>Procedimentos</small>
      </div>
      <table class="table table-hover" v-if="data.expenses && data.expenses.length > 0">
        <tbody>
        <tr v-for="(item, i) in data.expenses" :key="i">
          <td width="20px">{{ item.code | tuss }}</td>
          <td>{{ item.name }}</td>
          <td class="text-center">{{ item.quantity }}x</td>
          <td class="text-right">
            {{ item.value ? item.value.particular : 0 | currency }}
          </td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn btn-primary mr-2" @click="print">Imprimir</button>
        <button class="btn" @click="close">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { getName } from '@/data/appointment-statuses';

export default {
  props: {
    show: {
      type: Boolean,
    },
    data: {
      isExam: {
        type: Boolean,
      },
      appointmentId: {
        type: String,
      },
      date: {
        type: Date,
      },
      status: {
        type: String,
      },
      professionalName: {
        type: String,
      },
      patientName: {
        type: String,
      },
      insuranceName: {
        type: String,
      },
      expenses: {
        type: Array,
      },
    },
  },
  data() {
    return {
      getName,
      printing: false,
    };
  },
  methods: {
    print() {
      return this.$file
        .print('/appointments/print-appointment', { id: this.data.appointmentId })
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../assets/scss/variables';
  #modal-confirmation-list {
    .card-group {
      .card-value {
        color: $primary-color;
        font-weight: bold;
      }
    }
    .table td {
      font-size: 0.54rem;
    }
  }
</style>
