<template>
  <div class="appointment-document-modal">
    <dx-modal :title="`Imprimir documento para ${patient.name}`" size="lg"
              :value="show" @input="close" id="modal-print-document">
      <div class="columns form-group">
        <div class="column col-4 col-md-12 col-sm-12">
          <div class="input-group" :class="{loading}">
            <select class="form-select" v-model="modelId"
                    :disabled="loading" @change="getModel">
              <option value="">Meus modelos de impressos</option>
              <option v-for="(item, i) in models" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
            <button class="btn btn-neutral input-group-btn"
                    @click="openEditModel">
              Gerenciar
            </button>
          </div>
        </div>
      </div>
      <st-editor id="document-editor" ref="editorDocument"
                 v-model="document"></st-editor>
      <template slot="footer">
        <button class="btn btn-primary btn-icon btn-icon-left mr-1"
                :class="{loading: printing}"
                :disabled="printing"
                @click="print">
          <fa-icon :icon="['fal', 'print']"/>Imprimir
        </button>
        <button class="btn" @click="close">Sair</button>
      </template>
    </dx-modal>
    <modal-edit
      v-if="editModels.show"
      :show="editModels.show"
      @close="closeEditModel"
    />
  </div>
</template>

<script>
import moment from 'moment';
import DateOld from '@/filters/date-old';
import Cpf from '@/filters/cpf';
import Phone from '@/filters/phone';
import ModalEdit from '../../../template/components/appointment/modals/Main.vue';

export default {
  props: {
    show: {
      type: Boolean,
    },
    appointmentId: {
      type: String,
    },
    patientId: {
      type: String,
    },
  },
  components: {
    ModalEdit,
  },
  data() {
    return {
      loading: false,
      printing: false,
      document: '',
      models: [],
      modelId: '',
      appointment: {
        professional: {
          id: '',
          name: '',
        },
        insurance: {
          id: '',
          name: '',
          plan: {
            id: '',
            name: '',
          },
        },
        expenses: [],
        indicatedName: '',
      },
      patient: {
        id: '',
        name: '',
        birthDate: '',
        gender: '',
        identity: {
          type: 'cpf',
          value: '',
        },
        phone: '',
        email: '',
        cellphone: '',
        insurance: '',
      },
      editModels: {
        show: false,
      },
    };
  },
  mounted() {
    this.loadAppointment();
    this.loadPatient();
    this.loadModels();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    loadPatient() {
      return this.$http
        .get(`/patients/${this.patientId}`)
        .then(({ data }) => {
          this.patient.id = data.id;
          this.patient.name = data.name;
          this.patient.birthDate = data.birthDate;
          this.patient.gender = data.gender;
          this.patient.identity = data.identity;
          this.patient.phone = data.phone;
          this.patient.cellphone = data.cellphone;
          this.patient.email = data.email;
          this.patient.insurance = data.patient.insurance
            ? data.patient.insurance.name
            : null;
          this.patient.addresses = data.addresses;
        })
        .catch(() => {});
    },
    loadAppointment() {
      return this.$http
        .get(`/appointments/${this.appointmentId}`)
        .then(({ data }) => {
          this.appointment.insurance = data.insurance;
          this.appointment.professional = data.professional;
          this.appointment.expenses = data.expenses;
          this.appointment.indicatedName = data.indicated ? data.indicated.name : '';
        })
        .catch(() => {});
    },
    print() {
      this.printing = true;
      const params = {
        patientId: this.patientId,
        document: this.document,
      };
      return this.$file
        .print('/appointments/print-documents', params, { method: 'POST' })
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    loadModels() {
      const params = {
        limit: 0,
      };
      this.$http.get('/appointment-templates', { params })
        .then(({ data }) => {
          this.models = data.items;
        })
        .catch(() => {});
    },
    getModel() {
      if (!this.modelId) {
        return;
      }

      this.loadingModel = true;
      this.$http.get(`/appointment-templates/${this.modelId}`)
        .then(({ data }) => {
          if (data && data.data) {
            const address = this.patient.addresses
            && this.patient.addresses.length > 0
              ? this.patient.addresses[0] : null;

            this.document = data.data
              .replace(/\[date]/g, moment().format('DD/MM/YYYY'))
              .replace(/\[hour]/g, moment().format('HH:mm'))
              .replace(/\[professional]/g, this.appointment.professional.name)
              .replace(/\[insurance]/g, this.appointment.insurance.name)
              .replace(/\[patient_name]/g, this.patient.name)
              .replace(/\[patient_gender]/g,
                this.patient.gender === 'male'
                  ? 'Masculino'
                  : 'feminino')
              .replace(/\[patient_age]/g, DateOld(this.patient.birthDate, true))
              .replace(/\[patient_birth_date]/g,
                moment(this.patient.birthDate).format('DD/MM/YYYY'))
              .replace(/\[patient_cpf]/g,
                this.patient.identity && this.patient.identity.value
                  ? Cpf(this.patient.identity.value)
                  : null)
              .replace(/\[patient_phone]/g, Phone(this.patient.cellphone))
              .replace(/\[patient_email]/g, this.patient.email)
              .replace(/\[patient_address]/g, this.formatAddress(address))
              .replace(/\[expenses]/g, this.appointment.expenses
                .map(item => ` ${item.name}`))
              .replace(/\[indicated]/g, this.appointment.indicatedName);
            this.modelId = '';
          }
        })
        .catch(() => {})
        .then(() => {
          this.loadingModel = false;
        });
    },
    openEditModel() {
      this.editModels.show = true;
    },
    closeEditModel() {
      this.loadModels();
      this.editModels.show = false;
    },
    formatAddress(item) {
      if (item) {
        return `${
          item.addressLine1
        } ${
          item.addressLine2
        }, ${
          item.neighborhood
        } - ${
          item.city
        }-${
          item.state
        }`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../assets/scss/variables';
  .appointment-document-modal {
  }
  #modal-print-document {
    .document-editor-body {
      .ck.ck-content.ck-editor__editable {
        min-height: 30rem;
        padding: 15px 72px;
      }
    }
  }
</style>
