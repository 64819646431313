<template>
  <div class="surgical-team-modal">
    <form autocomplete="off" @submit.prevent.stop="addMember">
      <div class="columns mb-1 mt-2">
        <div class="column form-group">
          <dx-autocomplete
            v-model="professional"
            :source="findProfessional"
            label="name"
            track-by="id"
            :debounce="800"
            @select="addMember"
            placeholder="Informe o nome do profissional..."
            input-id="professional-name">
            <button
              slot="action"
              class="btn btn-action input-group-btn btn-icon btn-neutral"
              tabindex="-1"
              @click="openProfessionalModal"
            ><fa-icon :icon="['fal', 'search']"></fa-icon></button>
            <template v-slot="{ item }">
              <a>
                <span>{{ item.name }}</span>
                <span v-if="item.council" class="ml-1">
                  ({{ item.council.name }}-{{ item.council.state }} {{ item.council.record }})
                </span>
              </a>
            </template>
          </dx-autocomplete>
        </div>
      </div>
    </form>
    <template v-if="items.length > 0">
      <table class="table">
        <thead>
        <tr>
          <th style="width: 1rem">#</th>
          <th>Nome</th>
          <th>Conselho</th>
          <th style="width: 10rem">Atividade</th>
          <th style="width: 4rem" class="text-center">Principal</th>
          <th style="width: 2rem"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in items" :key="item.id">
          <td>{{ i + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>
            <span v-if="item.council">
              {{ item.council.name }}-{{ item.council.state }} {{ item.council.record }}
            </span>
          </td>
          <td>
            <select
              v-model="item.activity"
              class="form-select select-sm"
              v-if="!item.activity"
            >
              <option value="">[Selecione]</option>
              <option value="Cirurgião">Cirurgião</option>
              <option value="Anestesista">Anestesista</option>
              <option value="Instrumentador">Instrumentador</option>
            </select>
            <template v-else>
              <button
                class="btn btn-sm btn-action btn-icon btn-gray-outline"
                @click="item.activity = ''"
              ><fa-icon :icon="['fal', 'pencil']"></fa-icon></button>
              <span class="ml-2">{{ item.activity }}</span>
            </template>
          </td>
          <td class="text-center">
            <button
              class="btn btn-sm btn-action btn-link btn-icon"
              v-if="item.primary"
            ><fa-icon :icon="['fal', 'check-circle']"></fa-icon></button>
            <button
              class="btn btn-sm btn-action btn-link btn-icon"
              v-else
              @click="setPrimaryMember(item)"
            ><fa-icon :icon="['fal', 'circle']" class="text-gray"></fa-icon></button>
          </td>
          <td class="text-right">
            <button
              class="btn btn-sm btn-action btn-icon btn-gray"
              @click="removeMember(item)"
            ><fa-icon :icon="['fal', 'times']"></fa-icon></button>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <template v-else>
      <div class="empty">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Sem equipe</p>
        <p class="empty-subtitle">
          Nenhuma profissional adicionado. Informe o nome no campo acima para buscar.
        </p>
      </div>
    </template>

    <dx-modal title="Lista de profissionais"
              v-model="professionalModal.show" size="md"
              v-if="professionalModal.show"
              id="modal-professionals-appointment">
      <div class="empty mt-2"
           v-if="professionalModal.data.items.length === 0 && !professionalModal.loading">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
        </div>
        <p class="empty-title h6">Profissionais</p>
        <p class="empty-subtitle">
          Nenhum profissional cadastrado. Verifique as configurações.
        </p>
      </div>
      <div class="loading loading-lg mt-2" v-else-if="professionalModal.loading" />
      <table class="table table-hover expense-modal-table" v-else>
        <thead>
        <tr>
          <th>Nome</th>
          <th>Conselho</th>
        </tr>
        </thead>
        <tbody>
        <tr class="c-hand" v-for="(item, i) in professionalModal.data.items" :key="i"
            @click="addMember(item)">
          <td>{{ item.name }}</td>
          <td>
            <span v-if="item.council">
              {{ item.council.name }}-{{ item.council.state }} {{ item.council.record }}
            </span>
          </td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="professionalModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      professional: null,
      professionalModal: {
        loading: false,
        show: false,
        data: {
          items: [],
          hasMore: false,
          limit: 0,
          offset: 0,
        },
      },
    };
  },
  created() {
    this.form = this.$store.state.surgeryAppointment.form;
    if (this.form.professional.name) {
      this.addMember({
        ...this.form.professional,
        primary: true,
      });
    }
  },
  computed: {
    items() {
      return this.form.surgery.team;
    },
  },
  methods: {
    removeMember(member) {
      if (member.primary && this.form.surgery.team.length > 1) {
        this.form.surgery.team[0].primary = true;
      }
      const idx = this.form.surgery.team.indexOf(member);
      this.form.surgery.team.splice(idx, 1);
    },
    addMember(member) {
      // if (!Array.isArray(this.form.surgery.team)) {
      //   this.form.surgery.team = [];
      // }

      const exists = this.form.surgery.team.find(({ id }) => (id === member.id));
      if (!exists) {
        this.form.surgery.team.push({
          id: member.id,
          name: member.name,
          council: member.council,
          primary: this.form.surgery.team.length === 0,
          activity: '',
        });
      }

      this.professional = null;
      this.professionalModal.show = false;
    },
    setPrimaryMember(member) {
      this.form.surgery.team.forEach((item) => {
        item.primary = item.id === member.id;
      });
    },
    async findProfessional(search) {
      try {
        const { items } = await this.findProfessionals({ search });
        return items;
      } catch (e) {
        this.$toast.$error(e);
      }
      return [];
    },
    openProfessionalModal() {
      this.loadProfessionalModalItems();
      this.professionalModal.show = true;
    },
    async loadProfessionalModalItems() {
      this.professionalModal.loading = true;
      this.professionalModal.items = [];

      try {
        const { hasMore, items } = await this.findProfessionals();
        this.professionalModal.data.hasMore = hasMore;
        this.professionalModal.data.items = items;
      } catch (e) {
        this.$toast.$error(e);
      }

      this.professionalModal.loading = false;
    },
    async findProfessionals(params) {
      params = {
        offset: 0,
        limit: 20,
        ...params,
      };

      const { data } = await this.$http.get('/professionals', { params });
      data.items = data.items.map(item => ({
        id: item.id,
        name: item.name,
        council: item.professional.councils.length > 0
          ? item.professional.councils[0]
          : null,
      }));
      return data;
    },
  },
};
</script>
