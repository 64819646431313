<template>
  <dx-modal title="Imprimir agenda"
            :value="show" @input="close" id="modal-print-appointment">
    <div class="columns form-group">
      <div class="column col-6 col-sm-12 form-group"
           :class="{'has-error': $v.filter.startDate.$error}">
        <label class="form-label">Data inicial</label>
        <div class="input-group">
          <dx-input-date
            id="start-date"
            class="form-input text-center"
            v-model="filter.startDate"
            @blur="$v.filter.startDate.$touch()"
          />
        </div>
        <template v-if="$v.filter.startDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filter.startDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-if="!$v.filter.startDate.date">Data inválida</div>
        </template>
      </div>
      <div class="column col-6 col-sm-12 form-group"
           :class="{'has-error': $v.filter.endDate.$error}">
        <label class="form-label">Data final</label>
        <div class="input-group">
          <dx-input-date
            id="end-date"
            class="form-input text-center"
            v-model="filter.endDate"
            @blur="$v.filter.endDate.$touch()"
          />
        </div>
        <template v-if="$v.filter.endDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filter.endDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-if="!$v.filter.endDate.date">Data inválida</div>
          <div class="form-input-hint"
               v-else-if="!$v.filter.endDate.minDate">Data inválida</div>
        </template>
      </div>
      <div class="column col-12 form-group">
        <label for="schedule" class="form-label">Agenda</label>
        <select id="schedule" name="schedule"
                class="form-select" v-model="filter.scheduleId"
                @change="loadProfessionals">
          <option value="">Todas as agendas</option>
          <option v-for="(item, i) in schedules"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-12 form-group">
        <label for="insurance" class="form-label">Convênio</label>
        <select id="insurance" name="insurance"
                class="form-select" v-model="filter.insurancePlanId">
          <option value="">Todos os convênios</option>
          <option v-for="(item, i) in insurances"
                  :value="item.plan.id" :key="i">{{ item.customName }}</option>
        </select>
      </div>
      <div class="column col-12 form-group">
        <label for="professional" class="form-label">Profissional</label>
        <select id="professional" name="professional"
                class="form-select" v-model="filter.professionalId">
          <option value="">Todos os profissionais</option>
          <option v-for="(item, i) in professionals"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-12 form-group">
        <label for="type" class="form-label">Tipo</label>
        <select id="type" name="status"
                class="form-select" v-model="filter.type">
          <option value="">Todos os tipos</option>
          <option v-for="(text, value) in type.types"
                  :value="value" :key="value">{{ text }}</option>
        </select>
      </div>
    </div>
    <label class="form-label">Status</label>
    <div class="scroll-list-wrapper">
      <div class="scroll-list">
        <div class="list-header">
          <label class="form-checkbox">
            <input type="checkbox"
                   @change="selectAllStatuses"
                   :checked="getStatusSelected"
                   :indeterminate.prop="getStatusSelected === null">
            <i class="form-icon"></i>
          </label>
          <div class="list-title">Todos</div>
        </div>
        <table class="table table-hover">
          <tbody>
          <tr v-for="(item, i) in statuses" :key="i">
            <td width="30px">
              <label class="form-checkbox">
                <input type="checkbox" v-model="item.selected">
                <i class="form-icon"></i>
              </label>
            </td>
            <td>{{ item.name }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <template slot="footer">
      <button class="btn btn-gray btn-icon btn-icon-left mr-1"
              :class="{loading: printing && printType === 'sheet'}"
              :disabled="printing"
              @click="print(true)">
        <fa-icon :icon="['fal', 'file-excel']"/>Gerar planilha
      </button>
      <button class="btn btn-primary btn-icon btn-icon-left mr-1"
              :class="{loading: printing && printType === 'pdf'}"
              :disabled="printing"
              @click="print(false)">
        <fa-icon :icon="['fal', 'print']"/>Imprimir
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { statuses as appointmentStatuses } from '@/data/appointment-statuses';
import * as type from '@/data/appointment-types';
import { date, minDate } from '../../../../data/validators';

export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      statuses: [],
      loading: false,
      printing: false,
      insurances: [],
      schedules: [],
      professionals: [],
      type,
      printType: 'pdf',
      filter: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        scheduleId: '',
        professionalId: '',
        insurancePlanId: '',
        status: '',
        type: '',
      },
    };
  },
  validations() {
    const rules = {
      filter: {
        startDate: { required, date },
        endDate: { required, date },
      },
    };

    if (this.filter.startDate && moment(this.filter.startDate).isValid()) {
      rules.filter.endDate = {
        required,
        date,
        minDate: minDate(this.filter.startDate),
      };
    }

    return rules;
  },
  mounted() {
    this.loadInsurances();
    this.loadSchedules();
    this.loadStatuses();
  },
  computed: {
    getStatusSelected() {
      if (!this.statuses || this.statuses.length === 0) {
        return false;
      }

      const selected = this.statuses.filter(item => item.selected);
      if (selected.length === 0) {
        return false;
      }
      if (selected.length === this.statuses.length) {
        return true;
      }
      return null;
    },
  },
  methods: {
    loadStatuses() {
      this.statuses = appointmentStatuses
        .filter(({ key }) => !['blocked', 'available'].includes(key))
        .map(item => ({
          key: item.key,
          name: item.name,
          selected: true,
        }));
    },
    selectAllStatuses(e) {
      this.statuses.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    close() {
      this.$emit('close');
    },
    loadSchedules() {
      const params = {
        limit: 0,
        offset: 0,
        active: true,
      };

      return this.$http
        .get('/schedules', { params })
        .then(({ data }) => {
          this.schedules = data.items;
        })
        .catch(() => {});
    },
    loadProfessionals() {
      this.filter.professionalId = '';
      if (!this.filter.scheduleId) {
        this.professionals = [];
        return false;
      }

      const params = {
        limit: 0,
        offset: 0,
      };

      return this.$http
        .get(`/schedules/${this.filter.scheduleId}/professionals`, { params })
        .then(({ data }) => {
          this.professionals = data.items;
          if (data.items.length === 1) {
            this.filter.professionalId = data.items[0].id;
          }
        })
        .catch(() => {});
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurances', { params })
        .then(({ data }) => {
          this.insurances = data.items;
        })
        .catch(() => {});
    },
    print(sheet = false) {
      this.printType = sheet ? 'sheet' : 'pdf';

      this.printing = true;

      const params = {
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
      };

      if (this.filter.type) {
        params.type = this.filter.type;
      }

      if (this.filter.scheduleId) {
        params.scheduleId = this.filter.scheduleId;
      }

      if (this.filter.insurancePlanId) {
        params.insurancePlanId = this.filter.insurancePlanId;
      }

      if (this.filter.professionalId) {
        params.professionalId = this.filter.professionalId;
      }
      params.printType = this.printType;

      const statusItems = this.statuses
        .filter(({ selected }) => selected)
        .map(({ key }) => key);

      if (statusItems) {
        params.statuses = statusItems.join(',');
      }

      if (sheet) {
        return this.$file
          .download('/appointments/print', params)
          .catch(() => {})
          .then(() => {
            this.printing = false;
          });
      }

      return this.$file
        .print('/appointments/print', params)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../assets/scss/variables';
  @import '~assets/scss/mixins';
  #modal-print-appointment {
    .list-header {
      align-items: center;
      border-bottom: $border-width solid $border-color;
      display: flex;
      padding-bottom: $layout-spacing;
      .list-title {
        flex: 1;
        font-weight: bold;
      }
    }
    .scroll-list-wrapper {
      border: $border-color solid $border-width;
      border-radius: $border-radius;
      flex-grow: 1;
      height: 150px;
      margin-bottom: $layout-spacing-lg * 2;
      position: relative;
    }
    .scroll-list {
      bottom: 0;
      overflow-y: auto;
      padding: $layout-spacing $layout-spacing-lg;
      position: absolute;
      top: 0;
      width: 100%;
      @include scroll-bar();
      .table th, .table td {
        padding: 0;
      }
      .table td {
        border-bottom: none;
      }
    }
  }
</style>
