<template>
  <dx-modal title="Agendar como encaixe"
            :value="show" @input="close" id="modal-appointment-as-slot">
    <div class="card patient-card">
      <div class="card-header">
        <div class="card-title">Dados do paciente</div>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-6 mb-1">
            <strong>Nome:</strong>
            <span class="ml-1">{{ clipboard.data.patient.name }}</span>
          </div>
          <div class="column col-6 mb-1">
            <strong>Nascimento:</strong>
            <span class="ml-1">{{ clipboard.data.patient.birthDate | date }}</span>
          </div>
          <div class="column col-6">
            <strong>Celular:</strong>
            <span class="ml-1">{{ clipboard.data.patient.cellphone | phone }}</span>
          </div>
          <div class="column col-6">
            <strong>Telefone:</strong>
            <span class="ml-1">{{ clipboard.data.patient.phone | phone }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <!-- Schedule -->
      <div class="column col-4 form-group"
           :class="{'has-error': $v.form.scheduleId.$error}">
        <label for="slot-schedule" class="form-label">Agenda</label>
        <select id="slot-schedule" name="schedule"
                class="form-select" v-model="form.scheduleId"
                @change="loadProfessionals">
          <option value="">[Selecione]</option>
          <option v-for="(item, i) in schedules"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
        <template v-if="$v.form.scheduleId.$error">
          <div class="form-input-hint"
               v-if="!$v.form.scheduleId.required">Campo obrigatório</div>
        </template>
      </div>

      <!-- Professional -->
      <div class="column col-8 form-group"
           :class="{'has-error': $v.form.professional.id.$error}">
        <label for="slot-professional" class="form-label">Profissional</label>
        <select id="slot-professional" name="professional"
                class="form-select" v-model="professionalSpecialtyKey">
          <option value="">[Selecione]</option>
          <option v-for="item in professionalsSpecialty"
                  :value="item.key" :key="item.key">
            {{ item.name }}
            <template v-if="item.specialty">({{ item.specialty.name }})</template>
          </option>
        </select>
        <template v-if="$v.form.professional.id.$error">
          <div class="form-input-hint"
               v-if="!$v.form.professional.id.required">Campo obrigatório</div>
        </template>
      </div>

      <!-- Status -->
      <div class="column col-4 form-group">
        <label for="status" class="form-label">Status</label>
        <select id="status" name="status"
                class="form-select" v-model="form.status">
          <option v-for="(item, i) in statuses"
                  :value="item.key" :key="i">{{ item.name }}</option>
        </select>
      </div>

      <!-- Date -->
      <div class="column col-4 form-group"
           :class="{'has-error': $v.form.date.$error}">
        <label class="form-label" for="slot-date">Data</label>
        <dx-input-date
          id="slot-date"
          class="form-input text-center"
          v-model="form.date"
          @blur="$v.form.date.$touch()"
        />
        <template v-if="$v.form.date.$error">
          <div class="form-input-hint"
               v-if="!$v.form.date.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-if="!$v.form.date.date">Data inválida</div>
        </template>
      </div>

      <!-- Hour -->
      <div class="column col-4 form-group"
           :class="{'has-error': $v.form.hour.$error}">
        <label class="form-label" for="slot-hour">Hora</label>
        <input type="text" id="slot-hour" class="form-input text-center"
               v-model="form.hour" @blur="$v.form.hour.$touch()"
               placeholder="00:00" v-mask="'00:00'" ref="slotHour" />
        <template v-if="$v.form.hour.$error">
          <div class="form-input-hint"
               v-if="!$v.form.hour.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.form.date.hour">Hora inválida</div>
        </template>
      </div>
    </div>

    <template slot="footer">
      <button class="btn btn-primary mr-1"
              :class="{loading: saving}"
              @click="save" :disabled="saving">Agendar</button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { required } from 'vuelidate/lib/validators';
// eslint-disable-next-line import/no-named-default
import { default as statuses, STATUS_SCHEDULED } from '@/data/appointment-statuses';
import { date, hour } from '../../../../data/validators';

export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      statuses,
      professionals: [],
      form: {
        scheduleId: '',
        professional: {
          id: '',
          specialty: '',
        },
        date: '',
        hour: '',
        status: STATUS_SCHEDULED,
      },
      saving: false,
    };
  },
  validations: {
    form: {
      scheduleId: { required },
      professional: {
        id: { required },
      },
      date: { required, date },
      hour: { required, hour },
    },
  },
  mounted() {
    this.form.scheduleId = this.clipboard.data.scheduleId;
    this.form.date = this.date;
    this.loadProfessionals()
      .then(() => {
        this.form.professional = this.clipboard.data.professional;
        this.$refs.slotHour.focus();
      });
  },
  computed: {
    ...mapState({
      clipboard: ({ appointment }) => appointment.clipboard,
      schedules: ({ appointment }) => appointment.filter.schedules,
      date: ({ appointment }) => appointment.filter.date,
    }),
    professionalSpecialtyKey: {
      get() {
        if (this.form.professional.id) {
          return [
            this.form.professional.id,
            this.form.professional.specialty || '',
          ].join('-');
        }
        return '';
      },
      set(value) {
        [
          this.form.professional.id,
          this.form.professional.specialty,
        ] = value.split('-');
      },
    },
    professionalsSpecialty() {
      return this.professionals
        .reduce((result, professional) => {
          const specialties = professional.specialties.length > 0
            ? professional.specialties
            : [null];

          specialties.forEach((specialty) => {
            result.push({
              key: `${professional.id}-${specialty ? specialty.code : ''}`,
              id: professional.id,
              name: professional.name,
              council: professional.council,
              specialty,
            });
          });

          return result;
        }, []);
    },
  },
  methods: {
    ...mapMutations({
      clearClipboard: 'Appointment.CLEAR_CLIPBOARD',
    }),
    close() {
      this.$emit('close');
    },
    validate() {
      this.$v.$touch();
      return !this.$v.$error;
    },
    save() {
      if (!this.validate()) {
        return;
      }

      this.saving = true;
      const { data, event } = this.clipboard;
      const startDate = `${this.form.date}T${this.form.hour}`;

      const request = event === 'cut'
        ? this.updateAppointment(data.id, {
          scheduleId: this.form.scheduleId,
          startDate,
          endDate: startDate,
          professional: this.form.professional,
          originalKey: data.key,
          status: 'scheduled',
          slot: true,
        })
        : this.createAppointment({
          scheduleId: this.form.scheduleId,
          startDate,
          endDate: startDate,
          professional: this.form.professional,
          patient: data.patient,
          insurance: {
            id: data.insurance.id,
            record: data.insurance.record,
            validity: data.insurance.validity,
            planId: data.insurance.plan.id,
          },
          type: data.type || data.type,
          status: this.form.status,
          expenses: data.expenses,
          notes: data.notes,
          slot: true,
        });

      request
        .then(() => {
          this.$toast.show('Agendamento realizado');
          this.clearClipboard();
          this.close();
        })
        .catch(() => {})
        .then(() => {
          this.saving = true;
        });
    },
    loadSchedules() {
      return this.$http
        .get('/schedules')
        .then(({ data }) => {
          this.schedules = data.items;
        })
        .catch(() => {});
    },
    loadProfessionals() {
      this.form.professional.id = '';
      this.form.professional.specialty = '';
      if (!this.form.scheduleId) {
        this.professionals = [];
        return false;
      }
      return this.$httpX
        .get(`/schedules/${this.form.scheduleId}/professionals`)
        .then(({ data }) => {
          this.professionals = data.items;
        })
        .catch(() => {});
    },
    createAppointment(data) {
      return this.$http.post('/appointments', data);
    },
    updateAppointment(id, data) {
      return this.$http.put(`/appointments/${id}`, data);
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../assets/scss/variables';
  #modal-appointment-as-slot {
    .patient-card {
      background-color: $gray-color-ultra-light;
      margin-bottom: $layout-spacing;
    }
  }
</style>
